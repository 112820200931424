import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import DeclOfNum from './DeclOfNum'

export default function Countdown (selector = '.countdown-js', {
  time = null,
  classes = {},
  appendText = false
} = {}) {
  const $parent = typeof selector === 'string' ? document.querySelector(selector) : selector
  if (!isElement($parent)) return

  time = $parent.dataset.date || time
  appendText = $parent.dataset.appendtext || appendText
  if (!time) return

  let $days, $hours, $minutes, $seconds, timer
  const cls = getClasses(classes)

  init()

  function getClasses (cls) {
    return merge({
      days: 'countdown-js__days',
      hours: 'countdown-js__hours',
      minutes: 'countdown-js__minutes',
      seconds: 'countdown-js__seconds',
      parent: {
        complete: 'countdown_complete'
      }
    }, cls)
  }

  function init () {
    time = Date.parse(time)
    $days = $parent.querySelector(`.${cls.days}`)
    $hours = $parent.querySelector(`.${cls.hours}`)
    $minutes = $parent.querySelector(`.${cls.minutes}`)
    $seconds = $parent.querySelector(`.${cls.seconds}`)
    timer = setInterval(update, 1000)
  }

  function update () {
    const difference = time - +new Date()
    let remaining = null

    if (difference > 0) {
      remaining = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    render(remaining)
  }

  function render (time) {
    if (time) {
      if ($days) $days.innerText = time.days + `${appendText ? DeclOfNum(time.days, [' день', ' дня', ' дней']) : ''}`
      if ($hours) $hours.innerText = time.hours
      if ($minutes) $minutes.innerText = time.minutes
      if ($seconds) $seconds.innerText = time.seconds
    } else {
      clearInterval(timer)
      $parent.classList.add(cls.parent.complete)
    }
  }
}
