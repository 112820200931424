import isElement from 'lodash.iselement'

export default function ToggleChat (
  button = '.js-toggle-chat'
) {
  if (typeof button === 'string') {
    const arr = Array.from(document.querySelectorAll(button))
    arr.forEach((el) => {
      ToggleChat.apply({}, [el, arguments[1]])
    })
  } else if (isElement(button)) {
    init()
  }

  function init () {
    button.addEventListener('click', onClick)
  }

  function onClick (e) {
    e.preventDefault()
    if (window.jivo_api) {
      jivo_api.open()
    }
  }
}
