/*
  Слайдер с базовым функционалом перемещения влево-вправо, слайды внутри должны быть фиксированной ширины.
  Слайдер максимально легковестный, поэтому на производительность почти не влияет.

  По-мимо базового функционала предоставляет удобный внешний интерфейс: для смены классов у колонок, получения колонок,
  получения базовых значений и прочего.

  Параметры:
  container - элемент-обертка, можно передавать селектор или ноду

  Объект с опциональными параметрами:
  slideWidth - ширина каждого слайда/колонки в px, также можно передать значение auto чтобы инициализировать слайдер с колонками разной ширины
  enableResizeHandler - можно выключить обработчик при ресайзе окна
  classes - объект с классами, структуру объекта со стандартными классами можно увидеть ниже
  currentOffset - текущий сдвиг
  windowMinWidth - минимальная ширина окна при которой нужно активировать слайдер

  Следующие параметры можно передавать, чтобы получить выйгрыш в производительности. Чтобы слайдер сам не
  считал эти значения т.к. их вычисление вызывает repaint в некоторых случаях:
  maxOffset - максимальный сдвиг по горизонтали (ширина контейнера минус ширина движущейся части)
  containerWidth - ширина контейнера
  sliderWidth - ширина движущейся части
*/

import merge from 'lodash.merge'
import debounce from 'lodash.debounce'
import fastdom from 'fastdom'

export default function SimpleSlider (
  container = '.simple-slider',
  {
    slideWidth = 210,
    maxOffset,
    currentOffset = 0,
    containerWidth,
    sliderWidth,
    enableResizeHandler = true,
    navigation = true,
    classes = {},
    windowMinWidth = false
  } = {}
) {
  let cols, slider, leftArrow, rightArrow, navigationEnable
  try {
    container = typeof container === 'string' ? document.querySelector(container) : container
    if (!container || (windowMinWidth && window.innerWidth < windowMinWidth)) return

    classes = getClasses(classes)
    slider = container.querySelector(`.${classes.slider.base}`)
    updateCols()
    calculateVars({
      maxOffset,
      containerWidth,
      sliderWidth
    })
    if (enableResizeHandler) window.addEventListener('resize', debounce(update, 400))
    if (navigation) initNavigation()
  } catch (e) {
    console.error('Ошибка в слайдере: ', e)
  }

  function calculateVars (obj) {
    containerWidth = obj.containerWidth ? obj.containerWidth : container.offsetWidth
    // если у каждого слайда свой размер и если размер один для всех
    if (slideWidth === 'auto') {
      sliderWidth = obj.sliderWidth ? obj.sliderWidth : getSliderWidthForDynamicCols()
      maxOffset = obj.maxOffset ? obj.maxOffset : Math.min(containerWidth - sliderWidth, 0)
    } else {
      sliderWidth = obj.sliderWidth ? obj.sliderWidth : slideWidth * getColCount()
      maxOffset = obj.maxOffset ? obj.maxOffset : Math.min(containerWidth - slideWidth * getColCount(), 0)
    }
    currentOffset = sliderWidth > containerWidth ? Math.max(currentOffset, maxOffset) : 0
  }

  function getSliderWidthForDynamicCols () {
    let width = 0
    cols.forEach((el) => (width += el.offsetWidth))
    return width
  }

  function updateCols () {
    cols = Array.from(container.querySelectorAll(`.${classes.col.base}`))
  }

  function update ({ maxOffset, containerWidth, sliderWidth } = {}) {
    updateCols()
    calculateVars({
      offsetLimit: maxOffset,
      containerWidth,
      sliderWidth
    })
    setOffset()
  }

  function getClasses (obj) {
    return merge(
      {
        slider: {
          base: 'simple-slider__slider'
        },
        col: {
          base: 'simple-slider__col'
        },
        arrow: {
          base: 'simple-slider__arrow',
          left: 'simple-slider__arrow_left',
          right: 'simple-slider__arrow_right',
          active: 'simple-slider__arrow_active'
        }
      },
      obj
    )
  }

  function initNavigation () {
    leftArrow = container.querySelector(`.${classes.arrow.left}`)
    rightArrow = container.querySelector(`.${classes.arrow.right}`)

    leftArrow.addEventListener('click', toLeft.bind(null, 1))
    rightArrow.addEventListener('click', toRight.bind(null, 1))

    checkNavigation()
  }

  function toggleNavigation (leftState, rightState) {
    leftArrow.classList[leftState ? 'add' : 'remove'](classes.arrow.active)
    rightArrow.classList[rightState ? 'add' : 'remove'](classes.arrow.active)
  }

  function checkNavigation () {
    navigationEnable = !(maxOffset >= 0)
    if (!navigationEnable) {
      toggleNavigation(false, false)
    } else if (currentOffset === 0) {
      toggleNavigation(false, true)
    } else if (currentOffset <= maxOffset) {
      toggleNavigation(true, false)
    } else {
      toggleNavigation(true, true)
    }
  }

  function toLeft (count = 1) {
    // если у каждого слайда своя ширина листаем на полтора слайда среднего размера и если у всех одна, то там сложнее
    if (slideWidth === 'auto') {
      currentOffset += (sliderWidth / getColCount()) * 1.5
    } else {
      // Если скролл до этого произошел лишь на часть ширины колонки, выравниваем левый край страницы по крайней
      // левой колонке, по её левой стороне. Чтобы левая колонка шла точно по краю и не обрезалась
      if (count % 1 === 0 && currentOffset % slideWidth !== 0) {
        currentOffset += Math.abs(currentOffset) % slideWidth
      } else {
        currentOffset += count * slideWidth
      }
    }

    currentOffset = currentOffset >= 0 ? 0 : currentOffset
    setOffset()
  }

  function toRight (count = 1) {
    if (slideWidth === 'auto') {
      currentOffset -= (sliderWidth / getColCount()) * 1.5
    } else {
      if (count % 1 === 0 && currentOffset % slideWidth !== 0) {
        currentOffset -= slideWidth - (Math.abs(currentOffset) % slideWidth)
      } else {
        currentOffset -= count * slideWidth
      }
    }

    currentOffset = Math.max(currentOffset, maxOffset)
    setOffset()
  }

  function setOffset (size) {
    fastdom.mutate(() => {
      slider.style.transform = `translateX(${Math.round(size || currentOffset)}px)`
      if (navigation) checkNavigation()
    })
  }

  function columnInsert (from, to) {
    try {
      if (from < to) {
        const nextAfterTo = cols[to].nextSibling
        nextAfterTo ? slider.insertBefore(cols[from], nextAfterTo) : slider.appendChild(cols[from])
      } else if (from > to) {
        slider.insertBefore(cols[from], cols[to])
      }
      updateCols()
    } catch (e) {
      console.error(
				`Не удалось переместить колонку ${from} на место ${to}, скорее всего не удалось найти колонку которую перемещаем`,
				e
      )
    }
  }

  function columnSwap (from, to) {
    try {
      const nextAfterTo = cols[to].nextSibling
      const nextAfterFrom = cols[from].nextSibling
      nextAfterTo ? slider.insertBefore(cols[from], nextAfterTo) : slider.appendChild(cols[from])
      nextAfterFrom ? slider.insertBefore(cols[to], nextAfterFrom) : slider.appendChild(cols[to])
      updateCols()
    } catch (e) {
      console.error(`Не удалось поменять местами колонки ${from} и ${to}, скорее всего не удалось найти колонку которую перемещаем`,
        e
      )
    }
  }

  function getIndexOfCol (el) {
    return cols.indexOf(el)
  }

  function isAnyColHasClass (cls) {
    return cols.some((el) => el.classList.contains(cls))
  }

  function addClassForColByIndex (cls, index) {
    cols[index].classList.add(cls)
  }

  function getColByClass (cls) {
    return cols.find((el) => el.classList.contains(cls))
  }

  function getColCount () {
    return cols.length
  }

  function getCols () {
    return cols
  }

  function getCurrentOffset () {
    return currentOffset
  }

  function getMaxOffset () {
    return maxOffset
  }

  function setMaxOffset (offset) {
    maxOffset = offset
  }

  function log () {
    console.log('Дебаг компонента SimpleSlider.js:', {
      slideWidth,
      sliderWidth,
      containerWidth,
      maxOffset,
      currentOffset,
      navigationEnable,
      navigation
    })
  }

  return {
    slider,
    toLeft,
    toRight,
    columnInsert,
    columnSwap,
    update,
    getIndexOfCol,
    addClassForColByIndex,
    getColCount,
    getCols,
    isAnyColHasClass,
    getColByClass,
    getCurrentOffset,
    getMaxOffset,
    setMaxOffset,
    log
  }
}
