export default function IphonePreorder (parent) {
  const $parent = typeof parent === 'string' ? document.querySelector(parent) : parent
  if (!$parent) return

  const $nameInput = $parent.querySelector('.bem-input[name="name"]')
  const $cityInput = $parent.querySelector('.bem-input[name="city"]')
  const $phoneInput = $parent.querySelector('.bem-input[name="phone"]')
  const $error = $parent.querySelector('.form-error')
  const $button = $parent.querySelector('.bem-button')
  const url = 'https://script.google.com/macros/s/AKfycbw7bqB5KewlpfycbIU1Ote9b-T7us2bqKJ6PmCD-VQ8fYuST4U/exec'

  $button.addEventListener('click', onButtonClick)

  function onButtonClick () {
    if ($phoneInput.value) {
      const newUrl =
        url +
        '?name=' +
        ($nameInput.value || '-') +
        '&city=' +
        ($cityInput.value || '-') +
        '&phone=' +
        $phoneInput.value
      $button.classList.add('bem-button_success')
      $button.disabled = true
      $button.innerText = 'Заявка отправлена'
      $button.removeEventListener('click', onButtonClick)
      submit(newUrl)
      hideError()
    } else {
      showError()
    }
  }

  function showError () {
    $error.style.display = 'block'
  }

  function hideError () {
    $error.style.display = 'none'
  }

  function submit (url) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.send()
  }
}
