/*
  Простой таймер обратного отсчета: можно указывать
  кол-во секунд и события на начало и конец отсчета

  selector - элемент в который будет вставлен таймер
  time - кол-во секунд для отсчета

  Объект для дополнительных параметров:
  onStart - событие при окончании отсчета
  onEnd - событие при окончании отсчета
  updateEvery - как часто обновлять таймер в элементе, в секундах
  format - отображаем обратный отсчет в секундах или минутах
*/

import shareToGlobal from './ShareToGlobal.js'

export default function SimpleCountdown (selector, time, {
  onStart,
  onEnd,
  format,
  updateEvery = 1
} = {}) {
  let firstTimer, secondTimer, currentTime, el

  try {
    if (!selector) throw Error('Для инициализации таймера не передан селектор')
    el = document.querySelector(selector)
    if (!el) return
    time = time || +el.dataset.countdownSecond
    if (!time) throw Error('Для таймера не указано время обратного отсчёта')
    format = format || el.dataset.countdownFormat
    init()
  } catch (e) {
    console.error(e)
  }

  function init (t) {
    if (firstTimer || secondTimer) reset()
    currentTime = (t || time) + 1 // что бы начало отсчета также было видно в интерфейсе, иначе была бы видна вторая по счету секунда
    update()
    firstTimer = setInterval(update, updateEvery * 1000)
    secondTimer = setTimeout(reset, currentTime * 1000)
    onStart && onStart()
  }

  function update () {
    currentTime -= updateEvery
    el.innerText = currentTime >= 0 ? getFormattedTime(currentTime) : 0
  }

  function getFormattedTime (second) {
    if (format === 'minute') {
      const minute = leadingZero(Math.floor(second / 60))
      const remain = leadingZero(second % 60)
      return `${minute}:${remain}`
    } else {
      return second
    }
  }

  function leadingZero (time) {
    if (time === 0) {
      return '00'
    } else {
      return time < 10 ? `0${time}` : time
    }
  }

  function reset () {
    clearInterval(firstTimer)
    clearTimeout(secondTimer)
    onEnd && onEnd()
  }

  return {
    init,
    reset
  }
}

shareToGlobal.share('SimpleCountdown', SimpleCountdown)
