export default function Jivosite (
  {
    chatClass
  } = {}
) {
  if (!chatClass) return
  let $jivoWidget = null

  init()

  function init () {
    // Создаем элемент DIV для ярлыка
    $jivoWidget = document.createElement('div')
    $jivoWidget.setAttribute('class', chatClass)
    document.body.appendChild($jivoWidget)

    // Добавляем обработчик клика по ярлыку - чтобы при клике разворачивалось окно и скрывалась иконка чата
    $jivoWidget.addEventListener('click', onClick)

    // Теперь можно показать ярлык пользователю
    show($jivoWidget)
  }

  function onClick () {
    if (window.jivo_api) {
      jivo_api.open()
      hide($jivoWidget)
      const closeButton = document.querySelector('#jivo_close_button')
      // При клике на закрытие чата показываем иконку
      closeButton.addEventListener('click', close)
    }
  }

  function hide () {
    // Если чат развернут - скрываем ярлык
    $jivoWidget.style.display = 'none'
  }

  function show () {
    // Если чат свернут - показываем ярлык
    $jivoWidget.style.display = 'block'
  }

  function close () {
    show($jivoWidget)
  }
}
