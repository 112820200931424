/*
  Компонент  используется для того, чтобы очищать значение в input,
  selector - селектор обёртки всех элементов,
  input - элемент input для ввода текста,
  clear - кнопка для очистки input,
  state.empty - состояние кнопки при пустом input,
  state.fullfield - состояние кнопки при наличие текста input
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'

export default function FieldCleaner ({
  selector = '.js-field-cleaner',
  classes = {}
} = {}) {
  let $parent, $buttonCleaner, $input
  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      const param = { ...arguments[0] }
      param.selector = $el
      FieldCleaner(param)
    })
  } else if (isElement(selector)) {
    init()
  }
  function getClasses (obj) {
    return merge(
      {
        input: 'js-field-cleaner__input',
        clear: 'js-field-cleaner__clear',
        state: {
          empty: 'js-field-cleaner__empty',
          fullfield: 'js-field-cleaner__fullfield'
        }
      },
      obj
    )
  }
  function init () {
    classes = getClasses(classes)
    $parent = selector
    $input = $parent.querySelector(`.${classes.input}`)
    $buttonCleaner = $parent.querySelector(`.${classes.clear}`)
    $buttonCleaner.addEventListener('click', onClick)
    $input.addEventListener('keyup', onKeyUp)
  }
  function onKeyUp () {
    const textLength = $input.value.length
    if (textLength) {
      $parent.classList.remove(classes.state.empty)
      $parent.classList.add(classes.state.fullfield)
    } else {
      $parent.classList.remove(classes.state.fullfield)
      $parent.classList.add(classes.state.empty)
    }
  }
  function onClick (event) {
    event.preventDefault()
    $parent.classList.remove(classes.state.fullfield)
    $parent.classList.add(classes.state.empty)
    $input.value = ''
    $input.focus()
  }
}
