/*
  Объект с параметрами:
  {
    identifyer - string уникальный идентификатор, чтобы можно было различать разные блоки из хранилища
    interval - колличество дней через, которые срабатывает компнонент
    count - кол-во раз работы компонента
    showForCertainOS - array/string платформа для которой показываем блок, берется из строки userAgent
  }
*/

export default function ConditionsView ({
  identifyer = false,
  interval = 14,
  count = 1,
  showForCertainOS = false
} = {}) {
  const userAgent = navigator.userAgent.toLowerCase()
  let instance = false
  if (!identifyer) {
    throw Error(
      'Для промо баннера нужно передать уникальный идентификатор в параметрах'
    )
  }
  init()

  function init () {
    if (isProperOS(showForCertainOS)) {
      if (wasShown()) {
        if (parseInt(daysPassed()) > interval && showCount() > 1) {
          changeCountStore()
          instance = true
        }
      } else {
        instance = true
      }
    }
  }

  function isProperOS (os) {
    if (os) {
      if (os instanceof Array) {
        return os.some((el) =>
          userAgent.indexOf(el.toLowerCase()) !== -1
        )
      } else if (typeof os === 'string') {
        return userAgent.indexOf(os.toLowerCase()) !== -1
      }
    } else {
      return true
    }
  }

  function changeCountStore () {
    let getDays =
      +window.localStorage
        .getItem('сonditions-view-' + identifyer)
        .split(',')[1] - 1
    window.localStorage.setItem('сonditions-view-' + identifyer, [
      new Date().toJSON(),
      getDays--
    ])
  }

  function wasShown () {
    return window.localStorage.getItem('сonditions-view-' + identifyer)
  }

  function showCount () {
    return +wasShown().split(',')[1]
  }

  function daysPassed () {
    let date = window.localStorage
      .getItem('сonditions-view-' + identifyer)
      .split(',')[0]
    if (!date) return false

    date = new Date(date)
    const days = (new Date() - date) / 1000 / 60 / 60 / 24
    return days
  }

  function increaseCounter () {
    window.localStorage.setItem('сonditions-view-' + identifyer, [
      new Date().toJSON(),
      count
    ])
  }

  function isNeedToShow () {
    return instance
  }

  return {
    isNeedToShow, increaseCounter
  }
}
