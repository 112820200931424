// Клониерует элемент под селектором cloneSelector при клике на activatorSelector

export default class CloneEl {
  constructor (target = '.clone-el', {
    cloneSelector = '.clone-el__cloneable',
    activatorSelector = '.clone-el__activator',
    cloneCount = 'infinite'
  } = {}) {
    try {
      this.target = typeof target === 'string' ? document.querySelector(target) : target
      this.clone = this.target.querySelector(cloneSelector)
      this.clone.classList.remove(cloneSelector.replace('.', ''))

      this.cloneCount = +this.target.dataset.cloneCount || cloneCount
      this.cloneCount = this.cloneCount === 'infinite' ? 'infinite' : +this.cloneCount

      this.activator = this.target.querySelector(activatorSelector)
      this.init()
    } catch (e) {
      console.error('Ошибка при инициализации элемента для создания клонов', e)
    }
  }

  init () {
    this.activator.addEventListener('click', this.onActivatorClick.bind(this))
  }

  onActivatorClick (e) {
    this.createClone()
  }

  createClone () {
    if (this.reachTheLimit) return

    if (this.cloneCount !== 'infinite') {
      this.cloneCount -= 1
      if (this.cloneCount === 0) {
        this.activator.style.display = 'none'
        this.reachTheLimit = true
      };
    }

    this.target.insertBefore(this.clone.cloneNode(true), this.clone)
  }
}

CloneEl.init = function (target = '.clone-el', opts) {
  const arr = Array.from(document.querySelectorAll(target))
  arr.forEach((el) => {
    new CloneEl(el, opts)
  })
}
