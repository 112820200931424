/*
  Компонент для "выключения" ссылок при определенном размере окна браузер,
  если ссылка выключена то при клике на неё перехода происходить не будет

  Параметры:
  selector - селектор ссылки
  {
    maxWidth - максимальный размер окна, при котором и ниже ссылка будет выключена
    minWidth - минимальный размер окна, при котором и выше ссылка будет выключена
  }
*/

import isElement from 'lodash.iselement'

export default function DisableLink (selector = '.js-link-disable', { maxWidth, minWidth } = {}) {
  const el = typeof selector === 'string' ? Array.from(document.querySelectorAll(`${selector}`)) : selector
  if (el instanceof Array) {
    if (el.length > 1) {
      const arr = []
      el.forEach((e) => arr.push(DisableLink.apply({}, [e, arguments[1]])))
      return arr
    } else if (el.length === 0) {
      return null
    }
  }
  if (!isElement(el)) return

  maxWidth = maxWidth || +el.dataset.disableMaxWidth
  minWidth = minWidth || +el.dataset.disableMinWidth
  el.addEventListener('click', onClick)

  function onClick (e) {
    if (window.innerWidth <= maxWidth || minWidth <= window.innerWidth) e.preventDefault()
  }

  return {
    el,
    maxWidth,
    minWidth
  }
}
