/*
	Большой слайдер с фотографиями продукта, превьюшками и их автоматическим скролом

	Фичи:
	1. Просмотр фотографий во весь экран
	2. Увеличение фотографий, с разделением функционала на тач-увеличение и увеличение через мышь

	Параметры:
	{
		parent - node/селектор родительского элемента-оболочки
		classes - объект с классами используемыми в компоненте, структуру можно посмотреть в функции getClasses
		scrollLength - на какое кол-во превьюшек скроллить при переключении слайдов
		siemaOpts - объект, который напрямую передается в компонент Siema
		photoSwipe: {
			слайдер во весь экран, можно передать true для его активации или объект с доп. параметрами.
			Также на img элементах для работы этого слайдера должны быть атрибуты data-src, data-width и data-height
			onEnable - функция, вызывается при активации слайдера во весь экран, внутри один параметр это объертка слайдера во весь экран
			onDisable - вызывается при закрытии слайдера во весь экран, внутри один параметр это объертка слайдера во весь экран
		}
		zoom - имеет три состояния photoswipe(активирует слайдер), adaptive(активирует зум), joint(активирует слайдер в дестопе и тач зум на мобилках)
		onInit - функция, вызывается при активации слайдера
	}
*/

import Siema from './Siema.js'
import debounce from 'lodash.debounce'
import PinchZoom from 'pinch-zoom-js'
import isTouchDevice from 'is-touch-device'
import Drift from 'drift-zoom'
import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'

export default function ProductSlider (
  { parent = '.product-slider', classes, scrollLength = 2, siemaOpts, zoom = false, onInit = () => {} } = {}
) {
  let cls, $photoWrapper, $thumbWrapper, $thumbScrollWrapper, $thumbArr, $activeThumb, thumbScrollWrapperHeight, thumbHeight, maxScrollY, siemaInstance

  const $parent = typeof parent === 'string' ? Array.from(document.querySelectorAll(parent)) : parent
  if ($parent.length > 0) {
    $parent.forEach(($el) => {
      const param = { ...arguments[0] }
      param.parent = $el
      ProductSlider(param)
    })
  } else if (isElement($parent)) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    $thumbWrapper = $parent.querySelector(`.${cls.thumbWrapper.base}`)
    $photoWrapper = $parent.querySelector(`.${cls.photoWrapper.base}`)
    $thumbScrollWrapper = $parent.querySelector(`.${cls.thumbScrollWrapper.base}`)
    $thumbArr = Array.from($thumbScrollWrapper.children)
    $activeThumb = getActiveThumb()

    $thumbWrapper.addEventListener('click', onThumbsWrapperClick)
    recalculateVariables()
    document.addEventListener('resize', debounce(recalculateVariables, 300))

    siemaInstance = initSiema($parent, siemaOpts)
    initZoom(zoom)
    onInit()
  }

  function getActiveThumb () {
    const el = $thumbWrapper.querySelector(`.${cls.thumb.active}`)
    if (el) {
      return el
    } else {
      $thumbArr[0].classList.add(cls.thumb.active)
      return $thumbArr[0]
    }
  }

  function initZoom (type) {
    if (type === 'photoswipe') {
      initPhotoSwipe()
    } else if (type === 'adaptive') {
      initAdaptiveZoom()
    } else if (type === 'joint') {
      initJoint()
    } else {
      throw Error(`Выбран неправильный тип "${type}" для увеличения для слайдера, выберите один из валидных типов "photoswipe", "adaptive" или "joint"`)
    }
  }

  function initJoint () {
    isTouchDevice() ? initAdaptiveZoom() : initZoomButtonSwipe()
  }

  function initZoomButtonSwipe () {
    const $el = document.querySelector('.pswp')
    const data = getPhotoSwipeData()

    $parent.classList.add(cls.zoom.mouse.parent.base)
    const $zoomButton = $parent.querySelector(`.${cls.zoom.mouse.button.base}`)

    $zoomButton.addEventListener('click', (e) => {
      if (siemaInstance.isDragActive()) return
      enablePhotoSwipe({ $el, data })
    })
  }

  function initPhotoSwipe () {
    const $el = document.querySelector('.pswp')
    const data = getPhotoSwipeData()
    $photoWrapper.addEventListener('click', (e) => {
      if (siemaInstance.isDragActive()) return

      const target = e.target.closest(`.${cls.img.base}`)
      if (target) enablePhotoSwipe({ $el, data })
    })
  }

  function getPhotoSwipeData () {
    const data = []
    const $photoArr = Array.from($parent.querySelectorAll(`.${cls.img.base}`))
    $photoArr.forEach(($el) => {
      data.push({
        src: $el.dataset.src,
        msrc: $el.src,
        w: parseInt($el.naturalWidth),
        h: parseInt($el.naturalHeight)
      })
    })
    return data
  }

  function enablePhotoSwipe ({ $el, data }) {
    let startFromPhoto
    const pswpInstance = new PhotoSwipe($el, PhotoSwipeUI.default, data, {
      index: getThumbIndex($activeThumb),
      getThumbBoundsFn () {
        return getThumbBoundsFn()
      },
      shareEl: false,
      closeElClasses: ['p', 'h3', 'additional-text', 'item', 'caption', 'zoom-wrap', 'ui', 'top-bar', 'img']
    })

    pswpInstance.listen('afterChange', () => {
      toggle(pswpInstance.getCurrentIndex())
    })

    pswpInstance.listen('initialZoomIn', () => {
      startFromPhoto = $photoWrapper.querySelector(`.${cls.img.base}`)
      startFromPhoto.style.opacity = 0
    })

    pswpInstance.listen('destroy', () => {
      startFromPhoto.style.opacity = 1
    })

    pswpInstance.init()
  }

  function initAdaptiveZoom () {
    const $arr = Array.from($parent.querySelectorAll(`.${cls.zoom.wrapper.base}`))
    isTouchDevice() ? initTouchZoom($arr) : initMouseZoom($arr)
  }

  function initTouchZoom ($arr) {
    $parent.classList.add(cls.zoom.touch.parent.base)
    setTouchZoomWrapperSize($arr)
    $arr.forEach(($el) => {
      $el.classList.add(cls.zoom.wrapper.touch)
      new PinchZoom($el, {
        minZoom: 1,
        draggableUnzoomed: false,
        setOffsetsOnce: true
      })
    })

    window.addEventListener('resize', setTouchZoomWrapperSize.bind(null, $arr))
  }

  function setTouchZoomWrapperSize ($arr) {
    const $wrapper = $parent.querySelector(`.${cls.photoWrapper.base}`)
    $arr.forEach(($el) => {
      $el.style.width = $wrapper.offsetWidth + 'px'
      $el.style.height = $wrapper.offsetHeight + 'px'
    })
  }

  function initMouseZoom ($arr) {
    const draftInstanceArr = []
    $arr.forEach(($el) => {
      $el.classList.add(cls.zoom.wrapper.mouse)
      const d = new Drift($el.querySelector('.product-slider__photo-img'), {
        paneContainer: $parent.querySelector('.product-slider__zoom-box'),
        hoverBoundingBox: true,
        zoomFactor: 2.5,
        inlinePane: 1000,
        handleTouch: false
      })
      d.disable()
      draftInstanceArr.push(d)
    })

    $parent.classList.add(cls.zoom.mouse.parent.base)
    const $zoomButton = $parent.querySelector(`.${cls.zoom.mouse.button.base}`)
    $zoomButton.addEventListener('click', function (e) {
      onZoomButtonClick(this, draftInstanceArr)
    })
  }

  function onZoomButtonClick (button, draftArr) {
    const isActive = button.classList.contains(cls.zoom.mouse.button.active)
    const $zoomBlackout = $parent.querySelector(`.${cls.zoom.mouse.blackout.base}`)
    const $zoomBox = $parent.querySelector(`.${cls.zoom.mouse.box.base}`)
    isActive ? disable() : enable()

    function enable () {
      toggleZoomMode('add')
      siemaInstance.disable()
      $zoomBlackout.addEventListener('click', disable)
      $zoomBox.addEventListener('click', disable)
      draftArr.length === 1 ? draftArr[0].enable() : draftArr[siemaInstance.getCurrentIndex()].enable()
    }

    function disable () {
      toggleZoomMode('remove')
      siemaInstance.enable()
      $zoomBlackout.removeEventListener('click', disable)
      $zoomBox.removeEventListener('click', disable)
      draftArr.length === 1 ? draftArr[0].disable() : draftArr[siemaInstance.getCurrentIndex()].disable()
    }
  }

  function toggleZoomMode (action) {
    const $zoomButton = $parent.querySelector(`.${cls.zoom.mouse.button.base}`)
    $parent.classList[action](cls.zoom.mouse.parent.zoomMode)
    $zoomButton.classList[action](cls.zoom.mouse.button.active)
  }

  function getClasses (obj) {
    return merge(
      {
        zoom: {
          mouse: {
            parent: {
              base: 'product-slider_mouse-zoom',
              zoomMode: 'product-slider_zoom-mode'
            },
            button: {
              base: 'product-slider__zoom-button',
              active: 'product-slider__zoom-button_active'
            },
            box: {
              base: 'product-slider__zoom-box'
            },
            blackout: {
              base: 'product-slider__blackout'
            }
          },
          touch: {
            parent: {
              base: 'product-slider_touch-zoom'
            }
          },
          wrapper: {
            base: 'product-slider__photo-zoom-wrapper'
          }
        },
        img: {
          base: 'product-slider__photo-img'
        },
        photo: {
          base: 'product-slider__photo',
          active: 'product-slider__photo_active'
        },
        photoWrapper: {
          base: 'product-slider__photos',
          drag: 'product-slider__photos_drag'
        },
        thumb: {
          base: 'product-slider__thumb',
          active: 'product-slider__thumb_active'
        },
        thumbWrapper: {
          base: 'product-slider__thumbs'
        },
        thumbScrollWrapper: {
          base: 'product-slider__thumbs-wrapper'
        }
      },
      obj
    )
  }

  function initSiema ($el, opts) {
    return new Siema($el, {
      selector: `.${cls.photoWrapper.base}`,
      markCurrent: true,
      classes: {
        active: cls.photo.active,
        onDrag: cls.photoWrapper.drag
      },
      wrapperStyle: {
        display: 'block'
      },
      onChange (index) {
        const thumb = getThumbByIndex(index)
        thumb.classList.add(cls.thumb.active)
        onThumbClick(thumb)
      },
      ...opts
    })
  }

  function onThumbsWrapperClick (e) {
    const $thumb = e.target.closest(`.${cls.thumb.base}`)
    if (!$thumb) return
    onThumbClick($thumb)
  }

  function onThumbClick ($thumb) {
    const scrollTop = $thumbScrollWrapper.scrollTop
    const thumbPosition = $thumb.offsetTop - scrollTop
    let y = null

    if (thumbPosition < thumbScrollWrapperHeight * 0.25) {
      // если в верхней четверти
      y = scrollTop - thumbHeight * scrollLength
      if (y < 0) y = 0
    } else if (thumbPosition > thumbScrollWrapperHeight * 0.75) {
      // если в нижней четверти
      y = scrollTop + thumbHeight * scrollLength
      if (y > maxScrollY) y = maxScrollY
    }

    // если позиция скролла обновилась
    if (y !== null) {
      $thumbScrollWrapper.scrollTo({
        top: y,
        behavior: 'smooth'
      })
    }

    // ресетим превьюшки и изменяем позицию основного слайдера
    toggle(getThumbIndex($thumb))
  }

  function toggle (index, { siemaOpts } = {}) {
    siemaInstance.goTo(index, siemaOpts)
    toggleThumb(index)
  }

  function toggleThumb (index) {
    resetActiveThumb()
    $activeThumb = getThumbByIndex(index)
    $activeThumb.classList.add(cls.thumb.active)
  }

  function getThumbIndex (el) {
    return $thumbArr.indexOf(el)
  }

  function getThumbByIndex (index) {
    return $thumbArr[index]
  }

  function resetActiveThumb () {
    if ($activeThumb) $activeThumb.classList.remove(cls.thumb.active)
  }

  function recalculateVariables () {
    thumbScrollWrapperHeight = $thumbScrollWrapper.offsetHeight
    thumbHeight = $parent.querySelector(`.${cls.thumb.base}`).offsetHeight
    maxScrollY = $thumbScrollWrapper.scrollHeight - thumbScrollWrapperHeight
  }

  function getThumbBoundsFn () {
    const $photo = $photoWrapper.querySelector(`.${cls.photo.active} .${cls.img.base}`)
    if ($photo) {
      const rect = $photo.getBoundingClientRect()
      return {
        x: rect.left,
        y: rect.top + window.pageYOffset,
        w: rect.width
      }
    } else {
      const $photo = $photoWrapper.querySelector(`.${cls.img.base}`)
      const rect = $photo.getBoundingClientRect()
      return {
        x: rect.left,
        y: rect.top + window.pageYOffset,
        w: rect.width
      }
    }
  }

  return {
    $parent
  }
}
