import isElement from 'lodash.iselement'
import merge from 'lodash.merge'

export default function ScrollToBlock (
  selector = '.js-scroll-to-block',
  {
    classes = {}
  } = {}
) {
  let cls,
    $parent,
    $block

  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      ScrollToBlock($el, arguments[1])
    })
  } else if (isElement(selector) && !selector.dataset.productCardSliderActive) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    $parent = selector
    $block = document.querySelector(`.${cls.block}`)
    if ($block) {
      $parent.classList.add(cls.parent.active)
    } else {
      return
    }
    window.addEventListener('scroll', function (e) {
      if ($parent.getBoundingClientRect().top >= $block.getBoundingClientRect().top) {
        closeWindow()
      } else {
        openWindow()
      }
    })
  }

  function closeWindow () {
    $parent.classList.remove(cls.parent.active)
  }

  function openWindow () {
    $parent.classList.add(cls.parent.active)
  }

  function getClasses (obj) {
    return merge(
      {
        block: 'js-scroll-to-block__block',
        parent: {
          active: 'js-scroll-to-block_active'
        }
      },
      obj
    )
  }
}
