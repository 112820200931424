/*
  Компонент отвечающий за работу радио-фильтра, он состоит из списка фильтров и контентных блоков.
  При активации одного фильтра, активируется соответствующий контентный блок, а остальные скрываются.

  Параметры:
  container - родительский элемент

  Опциональный объект:
  classes - объект с перечнем классов, значение каждого можно увидеть ниже
*/

import merge from 'lodash.merge'

export default function RadioFilter (container, {
  classes = {}
} = {}) {
  let activeFilter, content, filter
  try {
    container = typeof container === 'string' ? document.querySelector(container) : container
    if (!container) throw Error('Не найден родительский элемент')

    classes = getClasses(classes)
    content = container.querySelectorAll(`.${classes.content.base}`)
    activeFilter = container.querySelector(`.${classes.filter.active}`)
    filter = container.querySelector(`.${classes.filter.container}`)
    filter.addEventListener('click', onClick)
  } catch (e) {
    console.error('Ошибка в радио-фильтре: ', e)
  }

  function getClasses (obj) {
    return merge(
      {
        filter: {
          container: 'radio-filter__sort-row',
          base: 'sort-row__el',
          active: 'sort-row__el_active',
          showAll: 'sort-row__el_show-all'
        },
        content: {
          base: 'radio-filter__content',
          hide: 'radio-filter__content_hide'
        }
      },
      obj
    )
  }

  function onClick (e) {
    const newActive = e.target.closest(`.${classes.filter.base}`)
    if (!newActive) return
    toggleFilter(newActive)

    if (activeFilter.classList.contains(classes.filter.showAll)) {
      reset()
    } else {
      showById(+activeFilter.dataset.groupId)
    }
  }

  function showById (id) {
    reset()
    content.forEach((el) => {
      if (+el.dataset.groupId === id) return
      el.classList.add(classes.content.hide)
    })
  }

  function reset ({
    resetFilter = false
  } = {}) {
    content.forEach((el) => {
      el.classList.remove(classes.content.hide)
    })

    if (resetFilter) toggleFilter(filter.querySelector(`.${classes.filter.showAll}`))
  }

  function toggleFilter (filter) {
    activeFilter && activeFilter.classList.remove(classes.filter.active)
    activeFilter = filter
    activeFilter.classList.add(classes.filter.active)
  }

  return {
    showById,
    reset
  }
}
