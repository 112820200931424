/*
  Компонент для показа превью для фотографий, которые выбраны через кнопку загрузки

  Параметры:
  selector - селектор или дом-нода обертки-родителя
  {
    classes - объект с классами
  }
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import PhotoswipePhotoGrid from './PhotoswipePhotoGrid'

export default function PhotoPreview (
  selector = '.js-photo-preview',
  { classes = {} } = {}
) {
  let cls, $parent, $row, $button

  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      PhotoPreview($el, arguments[1])
    })
  } else if (isElement(selector)) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    $parent = selector
    $row = $parent.querySelector(`.${cls.row}`)
    $button = $parent.querySelector(`.${cls.button}`)
    $button.addEventListener('change', onButtonClick)
  }

  function onButtonClick (e) {
    Array.from(e.target.files).forEach((el, index, arr) => {
      clear()
      const reader = new FileReader()
      reader.onload = function (e) {
        onReaderLoad(e, index, arr.length)
      }

      try {
        reader.readAsDataURL(el)
      } catch (e) {
        console.log('error', e)
      }
    })
  }

  function onReaderLoad (e, index, length) {
    const block = document.createElement('div')
    block.classList.add(cls.element)
    block.innerHTML = `
      <div class='${cls.photoWrapper}'>
        <img 
          class='${cls.photo} ${cls.photoSwipe}'
          src='${e.target.result}'
          data-src='${e.target.result}'
        />
      </div>
    `
    $row.appendChild(block)
    if (index === length - 1) PhotoswipePhotoGrid($row)
  }

  function clear () {
    Array.from($parent.querySelectorAll(`.${cls.element}`)).forEach(($el) => {
      $el.remove()
    })
  }

  function getClasses (obj) {
    return merge(
      {
        button: 'js-photo-preview__button',
        row: 'js-photo-preview__row',
        element: 'js-photo-preview__el',
        photoWrapper: 'js-photo-preview__photo-wrapper',
        photo: 'js-photo-preview__photo',
        photoSwipe: 'js-photo-preview__photo-swipe'
      },
      obj
    )
  }
}
