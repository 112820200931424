import seedrandom from 'seedrandom'
import shareToGlobal from './ShareToGlobal.js'

export default function ColorAvatar (selector = '.color-avatar', {
  valueAttribute = 'avatarSeed'
} = {}) {
  try {
    let el
    if (typeof selector === 'string') {
      const arr = document.querySelectorAll(selector)
      if (arr.length > 1) {
        arr.forEach((el) => ColorAvatar(el, arguments[1]))
        return
      } else if (arr.length === 1) {
        el = arr[0]
      } else {
        return
      }
    } else if (typeof selector === 'object') {
      el = selector
    }

    const sign = getFirstSign(el.dataset[valueAttribute])
    const color = getColor(sign)

    if (isAvatarNeed(el)) ApplyAvatar({ el, color, sign })
  } catch (e) {
    console.error(e)
  }

  function getColor (seed) {
    seed = seedrandom(seed)()
    return `hsl(${360 * seed}, 56%, 60%)`
  }

  function getFirstSign (phrase) {
    if (!phrase) throw Error("Для создания аватара нет Seed'a")
    return phrase[0]
  }

  function isAvatarNeed (el) {
    if (el.tagName === 'DIV') {
      const isUrlInside = /url\s?\((?:'|")(\S+)(?:'|")\)/im.test(el.style.backgroundImage)
      const alreadyActive = !!el.dataset.colorAvatarEnable
      return !(isUrlInside || alreadyActive)
    } else {
      throw Error('Элемент-аватар не DIV')
    }
  }

  function ApplyAvatar ({ el, color, sign }) {
    el.style.backgroundColor = color
    el.innerText = sign
    el.dataset.colorAvatarEnable = true
  }
}

shareToGlobal.share('ColorAvatar', ColorAvatar)
