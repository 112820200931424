/*
  selector - селектор основного блока-обертки

  Объект с параметрами:
  {
		identifyer - string уникальный идентификатор, чтобы можно было различать разные блоки из хранилища
		count - кол-во раз появления tippy
		interval - кол-во дней через которые будет вызываться функция
		showForCertainOS - операционная система для которой будет срабатывать компонент
    classes - object объект с классами для основных элементов, пример структуры ниже
    onEnable - function каллбэк при показе блока
    onDisable - function каллбэк при скрытии блока
  }
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import ConditionsView from './ConditionsView'

export default function NotificationBlock (
  selector,
  {
    identifyer = false,
    interval = 7,
    count = 1,
    showForCertainOS = 'android',
    classes = {},
    viewedOn = 'show',
    onEnable = () => null,
    onDisable = () => null
  } = {}
) {
  let cls, close, conditionInstance

  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      NotificationBlock($el, arguments[1])
    })
  } else if (isElement(selector)) {
    init()
  }

  function init () {
    identifyer = selector.dataset.identifyer || identifyer
    interval = selector.dataset.interval || interval
    count = selector.dataset.count || count
    showForCertainOS = selector.dataset.showCertain || showForCertainOS
    cls = getClasses(classes)
    close = selector.querySelector(`.${cls.close.base}`)

    conditionInstance = new ConditionsView({
      identifyer,
      count,
      interval,
      showForCertainOS
    })

    if (conditionInstance.isNeedToShow()) {
      enable()
      if (selector.dataset.viewedOn || viewedOn === 'show') {
        conditionInstance.increaseCounter()
      }
    }
  }

  function enable () {
    selector.classList.add(cls.promo.active)
    close.addEventListener('click', disable)
    onEnable()
  }

  function disable () {
    selector.classList.remove(cls.promo.active)
    close.removeEventListener('click', disable)
    onDisable()
    if (selector.dataset.viewedOn || viewedOn === 'close') {
      conditionInstance.increaseCounter()
    }
  }

  function getClasses (obj) {
    return merge(
      {
        promo: {
          base: 'app-promo',
          active: 'app-promo_active'
        },
        close: {
          base: 'app-promo__close'
        }
      },
      obj
    )
  }
}
