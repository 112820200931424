import merge from 'lodash.merge'

export default function FilterList ({
  selector = '.filter-list_js',
  classes = {}
} = {}) {
  const $filter = typeof selector === 'string' ? document.querySelector(selector) : selector
  if (!$filter) return
  const cls = getClasses(classes)

  const $toggle = Array.from($filter.querySelectorAll(`.${cls.toggle.base}`))
  $toggle.forEach($el => $el.addEventListener('click', onToggleClick))
  const $showMore = Array.from($filter.querySelectorAll(`.${cls.showMore.base}`))
  $showMore.forEach($el => $el.addEventListener('click', onShowMoreClick))

  function onToggleClick (e) {
    if (e.target.closest('.js-tippy')) return
    const $listItem = e.target.closest(`.${cls.listItem.base}`)
    if (!$listItem) return
    const isParentChild = this === $listItem.parentNode
    if ($listItem && isParentChild) this.classList.toggle(cls.toggle.active)
  }

  function onShowMoreClick (e) {
    const $listHiddenPart = this.closest(`.${cls.list.base}`).querySelector(
      `.${cls.listHiddenPart.base}`
    )
    if ($listHiddenPart) {
      $listHiddenPart.classList.toggle(cls.listHiddenPart.active)
      this.classList.toggle(cls.showMore.active)
    }
  }

  function getClasses (cls) {
    return merge(
      {
        toggle: {
          base: 'filter-list__toggle',
          active: 'filter-list__toggle_active'
        },
        list: {
          base: 'filter-list__hidden-list'
        },
        listItem: {
          base: 'filter-list__list-item'
        },
        showMore: {
          base: 'filter-list__show-subfilters',
          active: 'filter-list__show-subfilters_active'
        },
        listHiddenPart: {
          base: 'filter-list__hidden-subfilters',
          active: 'filter-list__hidden-subfilters_active'
        }
      },
      cls
    )
  }
}
