/*
  Компонент  используется для того, чтобы выводить окно после добавления товара в корзину

  selector - селектор основного блока-обертки

  Объект с параметрами:
  {
    classes - объект с классами
    time - кол-во миллисекунд через которое плашка закроется, если на неё не наведут
  }
*/

import isElement from 'lodash.iselement'
import merge from 'lodash.merge'
import shareToGlobal from './ShareToGlobal'

const list = []
let previous = null

export default function WindowCart (
  selector = '.window-cart',
  {
    classes = {},
    time = 5000
  } = {}
) {
  let $parent, $close, $button, timer
  if (typeof selector === 'string') {
    const $el = document.querySelector(selector)
    WindowCart($el, arguments[1])
  } else if (isElement(selector)) {
    init()
  }

  function init () {
    classes = getClasses(classes)
    $parent = selector
    time = +$parent.dataset.time || time
    $close = $parent.querySelector(`.${classes.close}`)
    $button = Array.from(document.querySelectorAll(`.${classes.button}`))
    $button.forEach($btn => $btn.addEventListener('click', onButtonClick))
    list.push(externalAPI())
  }

  function onButtonClick (event) {
    clearTimeout(timer)
    event.preventDefault()
    if (previous) {
      previous.close()
    }
    previous = externalAPI()
    open()
  }

  function open () {
    $parent.addEventListener('mouseover', clearTimer)
    $close.addEventListener('click', onCloseClick)
    changeWindow('add')
    timerClose()
  }

  function timerClose () {
    if (time) {
      timer = setTimeout(function () {
        changeWindow('remove')
      }, time)
    }
  }

  function clearTimer () {
    clearTimeout(timer)
  }

  function onCloseClick (event) {
    event.preventDefault()
    close()
  }

  function close (event) {
    $parent.removeEventListener('mouseover', clearTimer)
    $close.removeEventListener('click', onCloseClick)
    changeWindow('remove')
    clearTimer()
  }

  function changeWindow (move) {
    if (move === 'add') {
      $parent.classList.add(classes.active)
    } else if (move === 'remove') {
      $parent.classList.remove(classes.active)
    }
  }

  function getClasses (obj) {
    return merge({
      close: 'window-cart__close',
      button: 'window-cart-show',
      active: 'window-cart_active'
    },
    obj
    )
  }

  function externalAPI () {
    return {
      open,
      close,
      $parent
    }
  }

  return externalAPI()
}

function getWindowCart (node) {
  return list.find((el) => el.$parent === node)
}

shareToGlobal.share('WindowCart', WindowCart)
shareToGlobal.share('getWindowCart', getWindowCart)
