/*
  Компонент для поиска элементов в списке, также он поддерживает разделение элементов списка по группам и поиск по ним.
  listSelector - селектор обертки вокруг всех элементов, также можно передать ноду
  classes - классы для элементов поиска, структуру можно посмотреть ниже
  fuseOpts - можно передать кастомные параметры для поиска https://fusejs.io/
*/

import merge from 'lodash.merge'
import Fuse from 'fuse.js'

export default function ListSearch (
  listSelector = '.list-search_js',
  { classes = {}, fuseOpts = {} } = {}
) {
  const list = typeof listSelector === 'string' ? document.querySelector(listSelector) : listSelector
  if (!list) return

  const cls = getClasses(classes)
  let data = getDataFromListEl(list)
  const fuse = new Fuse(data, getFuseOpts(fuseOpts))
  const field = initField(list)
  const empty = list.querySelector(`.${cls.empty.base}`)
  let previousResult = []

  function getClasses (classes) {
    return merge(
      {
        field: {
          base: 'list-search__field',
          active: 'list-search__field_active'
        },
        list: {
          active: 'list-search_search-in-progress'
        },
        listEl: {
          base: 'list-search__list-el',
          active: 'list-search__list-el_active'
        },
        listGroup: {
          base: 'list-search__col',
          active: 'list-search__col_active'
        },
        empty: {
          base: 'list-search__empty',
          active: 'list-search__empty_active'
        }
      },
      classes
    )
  }

  // преобразуем элементы на странице в данные с которыми можно работать, работать напрямую с элементами - расточительно
  function getDataFromListEl (list) {
    let groupID = 0
    let elementID = 0
    const data = []
    const group = Array.from(list.querySelectorAll(`.${cls.listGroup.base}`))
    if (group.length > 0) {
      group.forEach(g => {
        const el = Array.from(g.querySelectorAll(`.${cls.listEl.base}`))
        if (el.length > 0) {
          g.dataset.searchGroupId = groupID
          el.forEach(e => {
            e.dataset.searchElId = elementID
            data.push({
              id: elementID,
              groupID: groupID,
              text: e.innerText
            })
            elementID += 1
          })
          groupID += 1
        }
      })
    } else {
      const el = Array.from(list.querySelectorAll(`.${cls.listEl.base}`))
      el.forEach(e => {
        e.dataset.searchElId = elementID
        data.push({
          id: elementID,
          text: e.innerText
        })
        elementID += 1
      })
    }
    return data
  }

  function getFuseOpts (opt) {
    return merge(
      {
        threshold: 0.25,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['text'],
        includeScore: true
      },
      opt
    )
  }

  function initField (list) {
    const field = list.querySelector(`.${cls.field.base}`)
    field.addEventListener('input', onFieldInput)
    return field
  }

  function onFieldInput (e) {
    const val = e.target.value.trim()
    if (val.length > 0) {
      toggleList()
      const result = fuse.search(val)
      handleResult(result)
    } else {
      reset()
    }

    e.target.value.length > 0 ? toggleField() : toggleField('off')
  }

  function toggleList (action = 'on') {
    list.classList[action === 'on' ? 'add' : 'remove'](cls.list.active)
  }

  function toggleField (action = 'on') {
    field.classList[action === 'on' ? 'add' : 'remove'](cls.field.active)
  }

  function handleResult (result) {
    console.log('Результат поиска по списку городов:', result)
    if (previousResult.length > 0) toggleActiveListEl(previousResult, 'off')
    result.length > 0 ? toggleEmptyBlock('off') : toggleEmptyBlock()
    toggleActiveListEl(result)
    sortSearchList(result)
    previousResult = result
  }

  function toggleActiveListEl (obj, action = 'on') {
    obj.forEach(el => {
      const l = list.querySelector(
        `.${cls.listEl.base}[data-search-el-id='${el.item.id}']`
      )
      if (el.item.groupID !== undefined) {
        const g = list.querySelector(
          `.${cls.listGroup.base}[data-search-group-id='${el.item.groupID}']`
        )
        g.classList[action === 'on' ? 'add' : 'remove'](cls.listGroup.active)
      }
      l.classList[action === 'on' ? 'add' : 'remove'](cls.listEl.active)
    })
  }

  function toggleEmptyBlock (action = 'on') {
    empty.classList[action === 'on' ? 'add' : 'remove'](cls.empty.active)
  }

  function sortSearchList (result) {
    resetSearchSort()
    result.forEach(el => {
      if (el.item.groupID !== undefined) {
        const $group = list.querySelector(
          `.${cls.listGroup.base}[data-search-group-id='${el.item.groupID}']`
        )
        if (
          $group.style.order === '' || +$group.style.order > el.score * 1000
        ) {
          $group.style.order = el.score * 1000 // convert a decimal point number into a natural one
        }
      }

      const $el = list.querySelector(
        `.${cls.listEl.base}[data-search-el-id='${el.item.id}']`
      )
      if ($el.style.order === '' || +$el.style.order > el.score * 1000) {
        $el.style.order = el.score * 1000 // convert a decimal point number into a natural one
      }
    })
  }

  function reset () {
    toggleList('off')
    toggleEmptyBlock('off')
    resetSearchSort()
  }

  function resetSearchSort () {
    const listEl = Array.from(list.querySelectorAll(`.${cls.listEl.base}`))
    listEl.forEach(el => {
      el.style.order = ''
    })

    const listGroup = Array.from(list.querySelectorAll(`.${cls.listGroup.base}`))
    listGroup.forEach(g => {
      g.style.order = ''
    })
  }

  function reload () {
    data = getDataFromListEl(list)
  }

  return {
    reset,
    reload
  }
}
