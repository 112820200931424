export default class NotificationPopUp {
  constructor (text, obj = {}) {
    this.text = text
    this.delay = Math.abs(obj.delay) || 3000
    this.createTemplate()
  }

  createTemplate () {
    this.notification = document.createElement('div')
    this.notification.classList.add('bem-notification', 'bem-notification_fixed')
    this.notification.innerText = this.text
    document.querySelector('body').appendChild(this.notification)
  }

  enable () {
    this.notification.classList.add('bem-notification_active')
    window.setTimeout(this.disable.bind(this), this.delay)
  }

  disable () {
    this.notification.classList.remove('bem-notification_active')
  }

  changeDelay (ms) {
    this.delay = ms
  }
}
