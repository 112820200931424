export default function InputNote (
  selector = '.input-note_js',
  { span = 'input-note__span', input = 'input-note__input', onInput } = {}
) {
  let $parent, $span, $input
  const self = this

  if (typeof selector === 'string') {
    const arr = document.querySelectorAll(selector)
    arr.forEach((el) => {
      InputNote.apply({}, [el, arguments[1]])
    })
  } else if (typeof selector === 'object') {
    init()
  }

  function init () {
    $parent = selector
    $span = $parent.querySelector(`.${span}`)
    $input = $parent.querySelector(`.${input}`)

    change()
    $input.addEventListener('input', onInputValueChange)
  }

  function change ({ value = $input.value || $input.placeholder, passive = false } = {}) {
    changeSpanText(value)
    changeInputWidth($span.offsetWidth)
    if (!passive) onInput && onInput.call(self, { value })
  }

  function changeSpanText (value) {
    if (value === 0) {
      $span.innerText = 0
    } else {
      $span.innerText = value || ''
    }
  }

  function changeInputWidth (width) {
    $input.style.width = Math.max(4, width) + 'px'
  }

  function onInputValueChange () {
    change({ value: this.value || this.placeholder })
  }

  return {
    change
  }
}
