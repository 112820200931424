// Логика работы меню категорий
import isElement from 'lodash.iselement'
import merge from 'lodash.merge'
import MenuAim from 'es6-menu-aim'

export default function MainMenu (button = '.js-catalog-button', {
  classes = {}
} = {}) {
  const $button = document.querySelectorAll(button)
  let activeNestedListEl
  let $catalog
  let $closeButton
  let $nestedCategory
  let $categoryMenuList
  let $allCategoryMenuList
  let $categoryMenuListWrapper
  let $categoryMenuElementWrapper
  let $parent
  let $title
  let $activeElement
  let $buttonBack
  let mainMenuTitle
  let mainMenuLink
  let defaultMenuTitle
  let instance
  let menuType
  let menuState = false
  const $body = document.body

  if (isElement(document.querySelector(button))) {
    init()
  }

  function getClasses (obj) {
    return merge({
      button: {
        base: 'js-header__catalog-wrapper',
        active: 'js-header__catalog-wrapper_active'
      },
      userPanelElement: {
        base: 'js-user-panel__el_mobile',
        active: 'js-user-panel__el_mobile_active'
      },
      wrapper: 'js-category-menu__wrapper',
      listWrapper: 'js-category-menu__list-wrapper',
      elementWrapper: 'js-category-menu__el-wrapper',
      list: 'js-category-menu__list',
      listElement: {
        base: 'js-category-menu__el_nested',
        active: 'js-category-menu__el_active'
      },
      closeButton: 'js-category-menu__close',
      mobileTitle: 'js-category-menu__header-title',
      elementTitle: 'js-category-menu__text',
      buttonBack: 'js-category-menu__back'
    },
    obj
    )
  }

  function init () {
    activeNestedListEl = []
    classes = getClasses(classes)
    $activeElement = document.querySelector(`.${classes.userPanelElement.active}`)
    $parent = document.querySelector(`.${classes.wrapper}`)
    $catalog = document.querySelector(`.${classes.button.base}`)
    $title = $parent.querySelector(`.${classes.mobileTitle}`)
    $buttonBack = $parent.querySelector(`.${classes.buttonBack}`)
    $buttonBack = $parent.querySelector(`.${classes.buttonBack}`)
    mainMenuTitle = $title.textContent
    mainMenuLink = $title.getAttribute('href')
    defaultMenuTitle = $title.textContent

    $button.forEach((item) => {
      item.addEventListener('click', toggleCatalog)
    })

    $closeButton = $parent.querySelector(`.${classes.closeButton}`)
    $nestedCategory = $parent.querySelectorAll(`.${classes.listElement.base}`)
    $categoryMenuList = $parent.querySelector(`.${classes.list}`)
    $allCategoryMenuList = $parent.querySelectorAll(`.${classes.list}`)
    $categoryMenuListWrapper = $parent.querySelector(`.${classes.listWrapper}`)

    menuType = calculateMenuType()

    menuType === 'desktop' ? enableDesktop() : enableMobile()

    window.addEventListener('resize', function () {
      const newMenuType = calculateMenuType()
      if (newMenuType !== menuType) {
        if (newMenuType === 'desktop') {
          disableMobile()
          enableDesktop()
        } else if (newMenuType === 'mobile') {
          disableDesktop()
          enableMobile()
          window.removeEventListener('click', onWindowClick)
        }
        menuType = newMenuType
      }
    })
  }

  function bodyOverflow () {
    if (window.innerWidth <= 800 && $catalog.classList.contains(classes.button.active)) {
      $body.style.overflow = 'hidden'
    } else if (window.innerWidth <= 800 && !$catalog.classList.contains(classes.button.active)) {
      $body.style.overflow = ''
    }
  }

  function calculateMenuType () {
    if (window.innerWidth <= 800) {
      return 'mobile'
    } else {
      return 'desktop'
    }
  }

  function enableDesktop () {
    $nestedCategory[0].classList.add(classes.listElement.active)
    const menuRoot = $parent.querySelector(`.${classes.list}`)

    instance = new MenuAim.MenuAim(menuRoot, {
      activate: function (row) {
        row.classList.add(classes.listElement.active)
      },
      deactivate (row) {
        row.classList.remove(classes.listElement.active)
      },
      exitMenu: function () {
        return false
      },
      rowSelector: `.${classes.listElement.base}`
    })
  }

  function enableMobile () {
    if ($catalog.classList.contains(classes.button.active)) {
      $body.style.overflow = 'hidden'
    }
    $title.innerHtml = defaultMenuTitle
    $closeButton.addEventListener('click', closeCatalog)
    $nestedCategory.forEach((item) => {
      item.addEventListener('click', enableMenuEl)
    })
    $buttonBack.addEventListener('click', backToPrevious)
  }

  function disableDesktop () {
    if (instance) {
      instance.detach()
    }
    $nestedCategory.forEach((item) => {
      item.classList.remove(classes.listElement.active)
    })
    $title.textContent = mainMenuTitle
    $title.setAttribute('href', mainMenuLink)
  }

  function disableMobile () {
    $body.style.overflow = ''
    $allCategoryMenuList.forEach((item) => {
      item.classList.remove(`${classes.list}_current`)
    })
    hideBack()
    activeNestedListEl = []
    $nestedCategory.forEach((item) => {
      item.classList.remove(classes.listElement.active)
      item.removeEventListener('click', enableMenuEl)
      item.removeEventListener('click', closeCatalog)
    })
    $categoryMenuList.style.transform = ''
  }

  function toggleCatalog (event) {
    event.preventDefault()
    if (menuState) {
      reset()
    } else {
      enable()
    }
    defaultMenuTitle = $title.innerText
    bodyOverflow()
  }

  function addMobileUserPanelClass () {
    $button.forEach((item) => {
      item.classList.add(classes.userPanelElement.active)
    })
  }

  function removeMobileUserPanelClass () {
    $button.forEach((item) => {
      item.classList.remove(classes.userPanelElement.active)
    })
  }

  function enable () {
    menuState = true
    $catalog.classList.add(classes.button.active)
    if ($activeElement) {
      $activeElement.classList.remove(classes.userPanelElement.active)
    }
    addMobileUserPanelClass()

    if (menuType === 'mobile') {
      window.requestAnimationFrame(() => {
        window.removeEventListener('click', onWindowClick)
      })
    } else {
      window.requestAnimationFrame(() => {
        window.addEventListener('click', onWindowClick)
      })
    }
  }

  function reset () {
    menuState = false
    $catalog.classList.remove(classes.button.active)
    if ($activeElement) {
      $activeElement.classList.add(classes.userPanelElement.active)
    }
    removeMobileUserPanelClass()
    if (menuType === 'desktop') {
      window.removeEventListener('click', onWindowClick)
    }
  }

  function onWindowClick (e) {
    const isInsideMenu = e.target.closest(`.${classes.list}`)
    if (!isInsideMenu) {
      reset()
    }
  }

  function closeCatalog () {
    $catalog.classList.remove(classes.button.active)
    $body.style.overflow = ''
    menuState = false
    removeMobileUserPanelClass()
  }

  function setTitle (el) {
    let text = defaultMenuTitle
    if (el) {
      text = el.querySelector(`.${classes.elementTitle}`).innerText
      $categoryMenuElementWrapper = el.querySelector(`.${classes.elementWrapper}`)
      $title.setAttribute('href', $categoryMenuElementWrapper.getAttribute('href'))
    }
    $title.innerText = text
    $title.style.borderBottom = '1px solid #e0e0e0'
  }

  function showBack () {
    $buttonBack.style.display = 'block'
  }

  function hideBack () {
    $buttonBack.style.display = 'none'
    $title.style.borderBottom = ''
  }

  function shiftList (n) {
    $categoryMenuListWrapper.scrollTo(pageYOffset, 0)
    $categoryMenuList.style.transform = `translateX(-${n * 100}%)`
  }

  function enableMenuEl (event) {
    const isActive = this.classList.contains(classes.listElement.active)
    if (isActive) return
    event.preventDefault()
    activeNestedListEl.push(this)
    setTitle(activeNestedListEl[activeNestedListEl.length - 1])
    showBack()
    this.classList.add(classes.listElement.active)
    this.closest(`.${classes.list}`).classList.add(`${classes.list}_current`)
    shiftList(activeNestedListEl.length)
  }

  function backToPrevious () {
    const el = activeNestedListEl.pop()
    el.classList.remove(classes.listElement.active)
    el.closest(`.${classes.list}`).classList.remove(`${classes.list}_current`)
    setTitle(activeNestedListEl[activeNestedListEl.length - 1])
    shiftList(activeNestedListEl.length)
    if (activeNestedListEl.length === 0) {
      hideBack()
      $title.textContent = mainMenuTitle
      $title.setAttribute('href', mainMenuLink)
    }
  }
}
