/*
  Компонент, который позволяет добавить к карточкам товаров (.product-card) функционал показа галереи фотографий при клике на фото.
  Чтобы галерея работала, нужно строго соблюдать html структуру. Для примера можно посмотреть её на странице сравнения.

  Параметры:
  container - элемент-обёртка для карточек товаров
  параметр photoSwipeOptions внутри опционально объекта принимает список вот этих https://photoswipe.com/documentation/options.html параметров
*/

import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import merge from 'lodash.merge'

export default function ProductCardPhotoSwipe (container = '.product-card-grid_js-photoswipe', {
  photoSwipeOptions = {}
} = {}) {
  try {
    container = typeof container === 'string' ? document.querySelector(container) : container
    if (!container) throw Error('Не получилось найти контейнер для карточек товаров с функционалом галереи во весь экран')

    container.addEventListener('click', onPhotoClick)
    photoSwipeOptions = getPhotoSwipeOptions(photoSwipeOptions)
  } catch (e) {
    console.error('Ошибка при инициализации PhotoSwipe для карточек товаров', e)
  }

  function getPhotoSwipeOptions (opts) {
    return merge({
      index: 0,
      hideAnimationDuration: 0,
      showAnimationDuration: 0,
      shareEl: false,
      closeElClasses: [
        'p',
        'h3',
        'additional-text',
        'item',
        'caption',
        'zoom-wrap',
        'ui',
        'top-bar',
        'img'
      ]
    },
    opts)
  }

  function onPhotoClick (e) {
    const el = e.target.closest('.product-card-compare__photo-wrapper_zoom')
    if (!el) return

    const data = parseData(el)
    const pswp = document.querySelector('.pswp')
    const photoSwipe = new PhotoSwipe(pswp, PhotoSwipeUIDefault, data, photoSwipeOptions)

    photoSwipe.init()
  }

  function parseData (el) {
    const data = []
    const photos = Array.from(el.closest('.product-card-compare').querySelectorAll('.product-card-compare__photo-zoom-el'))
    photos.forEach((el) => {
      data.push({
        src: el.dataset.src,
        msrc: el.src,
        w: el.naturalWidth,
        h: el.naturalHeight
      })
    })
    return data
  }
}
