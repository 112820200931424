// Универсальные скрипты для мобильной и десктопной версии

// Сторонние библиотеки
import StickySidebar from 'sticky-sidebar'

import tippy, { roundArrow } from 'tippy.js'
import 'dragscroll'
import LazyLoad from 'vanilla-lazyload'

// Онлайн чат
import Jivosite from './component/Jivosite.js'

// Наши модули
import Toggle from './component/Toggle.js'
import ToggleChat from './component/ToggleChat.js'
import FitListToBlock from './component/FitListToBlock.js'
import Modal from './component/Modal.js'
import TextareaDynamicHeight from './component/TextareaDynamicHeight.js'
import Siema from './component/Siema.js'
import TabManager from './component/TabManager.js'
import ProductSlider from './component/ProductSlider'
import ProductRating from './component/ProductRating'
import ColorAvatar from './component/ColorAvatar'
import NotificationPopUp from './component/NotificationPopUp'
import RangeInput from './component/RangeInput'
import InputNote from './component/InputNote'
import SimpleCountdown from './component/SimpleCountdown'
import TogglePasswordVisibility from './component/TogglePasswordVisibility'
import CopyToClickboard from './component/CopyToClickboard'
import ShowHidden from './component/ShowHidden'
import {
  CompareController,
  toggleCompare,
  getCompare
} from './component/CompareController'
import SimpleSlider from './component/SimpleSlider'
import ListSearch from './component/ListSearch'
import PhotoswipePhotoGrid from './component/PhotoswipePhotoGrid'
import FilterList from './component/FilterList'
import ToolTooltip from './component/ToolTooltip'
import PhotoPreview from './component/PhotoPreview'
import ShowFeatureTippy from './component/ShowFeatureTippy'
import DisableLink from './component/DisableLink'
import shareToGlobal from './component/ShareToGlobal'
import NotificationBlock from './component/NotificationBlock'
import CloneEl from './component/CloneEl'
import SimpleCallbackForm from './component/SimpleCallbackForm'
import AuctionValidation from './component/AuctionValidation'
import ScrollMenu from './component/ScrollMenu'
import MainMenu from './component/MainMenu'
import FieldCleaner from './component/FieldCleaner'
import ProductCardSlider from './component/ProductCardSlider'
import WindowCart from './component/WindowCart'
import IphonePreorder from './component/IphonePreorder'
import InputCounter from './component/InputCounter'
import ScrollToBlock from './component/ScrollToBlock'
import Countdown from './component/Countdown'

window.addEventListener('load', () => {
  // Онлайн чат
  Jivosite({
    chatClass: 'jivo-custom-widget'
  })

  FieldCleaner()

  // слайдеры
  shareToGlobal.share('Siema', Siema)

  // "выключение" ссылок при определенном размере окна
  DisableLink()

  // Формы на странице Кредита и рассрочки
  TabManager.init({
    parentSelector: '.bank-block_with-form',
    tab: 'bank-block__calc',
    wrapper: 'bank-block__form',
    onChange (wrapper) {
      if (!wrapper.dataset.formIsActive) {
        // Инпуты со слайдером и подсказкой рядом с полем вода
        const arr = Array.from(wrapper.querySelectorAll('.range-input_js-inside-bank-form'))

        this.rangeInputArr = []
        arr.forEach((el) => {
          this.rangeInputArr.push(
            new RangeInput(el, {
              onInit ({
                parent
              }) {
                this.InputNote = new InputNote(parent.querySelector('.input-note_inside-range-input'))
              },
              onRangeChange ({
                value
              }) {
                this.InputNote.change({
                  value,
                  passive: true
                })
              }
            })
          )
        })
        wrapper.dataset.formIsActive = true
      } else {
        // при повторном открытии обновляем вид, если изменилось, например, разрешение экрана
        this.rangeInputArr.forEach((el) => {
          el.rangeInstance._update()
        })
      }
    }
  })

  // Инпуты со слайдером и подсказкой рядом с полем вода
  RangeInput('.range-input_js-input-note', {
    initialPlaceholderSync: true,
    onInit ({
      parent
    }) {
      this.InputNote = new InputNote(parent.querySelector('.input-note_inside-range-input'))
    },
    onRangeChange ({
      value
    }) {
      this.InputNote.change({
        value,
        passive: true
      })
    }
  })

  // Инпуты со слайдером
  RangeInput()

  // компонент рейтинга на странице сборки компьютера
  ProductRating('.bem-product-rating_interactive', {
    classes: {
      button: {
        base: 'bem-product-rating__el',
        active: 'bem-product-rating__el_active'
      }
    }
  })

  // Инпуты с подсказкой рядом с полем ввода
  InputNote()

  // Автогенерируемые аватарки
  ColorAvatar('.color-avatar')

  // Инициализация модальных окон
  Modal.init('.bem-modal-show')

  /* показ скрытого блока при клике на активатор */
  ShowHidden()

  // Textarea высота которой увеличивается по мере набора текста
  TextareaDynamicHeight('.bem-input_textarea-dynamic-height')

  /* стандартные вкладки */
  TabManager.init({
    parentSelector: '.tab-toggle',
    tab: 'tab-toggle__tab',
    wrapper: 'tab-toggle__content'
  })

  /* вкладки на странице продукта */
  TabManager.init({
    parentSelector: '.product-page__description',
    tab: 'tabs-row__tab',
    wrapper: 'product-page__tab-content'
  })

  /* вкладки на странице корзины */
  TabManager.init({
    parentSelector: '.cart-order__form',
    tab: 'cart-order__delivery-tab',
    wrapper: 'cart-order__tab-content'
  })

  /* вкладки на странице корзины при выборе карты оплаты */
  TabManager.init({
    parentSelector: '.cart-order__tab-content',
    tab: 'cart-order__radio',
    wrapper: 'cart-order__radio-content'
  })

  /* вкладки для горизонтального блока с товарами */
  function productSliderWithTabs (wrapper) {
    return new Siema(wrapper, {
      selector: '.row-slider__slider',
      slideWidth: '.row-slider__product',
      wrapperStyle: {
        display: 'block'
      },
      arrows: true,
      arrowSlideThrough: 3,
      classes: {
        prev: 'row-slider__arrow_left',
        next: 'row-slider__arrow_right'
      }
    })
  }

  TabManager.init({
    parentSelector: '.row-slider_with-tabs',
    tab: 'underline-tabs__tab',
    wrapper: 'row-slider__content',
    onInit (wrapper) {
      productSliderWithTabs(wrapper)
    },
    onChange (wrapper) {
      if (!wrapper.dataset.siemaIsActive) {
        productSliderWithTabs(wrapper)
      } else {
        Siema.get(wrapper).update()
      }
    }
  })

  function cartSliderWithTabs (wrapper) {
    return new Siema(wrapper, {
      selector: '.row-slider__slider',
      slideWidth: '.row-slider__product',
      wrapperStyle: {
        display: 'block'
      },
      arrows: true,
      arrowSlideThrough: 3,
      classes: {
        prev: 'row-slider__arrow_left',
        next: 'row-slider__arrow_right'
      }
    })
  }

  TabManager.init({
    parentSelector: '.cart__description',
    tab: 'tabs-row__tab',
    wrapper: 'cart__tab-content',
    onInit (wrapper) {
      cartSliderWithTabs(wrapper)
    },
    onChange (wrapper) {
      if (!wrapper.dataset.siemaIsActive) {
        cartSliderWithTabs(wrapper)
      } else {
        Siema.get(wrapper).update()
      }
    }
  })

  // слайдер элементов с кастомными размерами
  Siema.init('.row-slider_custom-el', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider-custom-el',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    arrowSlideThrough: 1,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // Одиночный слайдер товаров
  Siema.init('.row-slider_single', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider__product',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    arrowSlideThrough: 3,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  Siema.init('.row-picture-link_js-slider', {
    selector: '.row-slider__slider',
    slideWidth: '.row-picture-link__el',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    arrowSlideThrough: 1,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // Слайдер баннеров акций и новостей
  Siema.init('.main-slider', {
    selector: '.main-slider__slides',
    cloneCount: 2,
    loop: true,
    autoplay: 3200,
    markCurrent: true,
    asideSlideClickNav: true,
    checkSlidesFill: false,
    classes: {
      active: 'main-slider__slide-wrapper_active'
    }
  })

  // Слайдер узких баннеров во всю ширину
  Siema.init('.banner-slider', {
    selector: '.banner-slider__wrapper',
    arrows: true,
    classes: {
      prev: 'banner-slider__arrow_left',
      next: 'banner-slider__arrow_right',
      notEnoughSlides: 'banner-slider_disable-arrows'
    },
    wrapperStyle: {
      display: 'block'
    }
  })

  // слайдер на странице продукта
  ProductSlider({
    zoom: 'joint'
  })

  // компонент отвечающий за скрытие/показ пароля
  new TogglePasswordVisibility({
    field: 'bem-input-icon_toggle-password',
    input: 'bem-input-icon__input',
    button: 'bem-input-icon__icon'
  })

  // копирование значения в буфер
  CopyToClickboard()

  // копирование значения в буфер и скрытие блока
  CopyToClickboard('.cart-copy-to-clickboard', {
    onClick ({ $parent }) {
      $parent.classList.add('cart-copy-to-clickboard_active')
    }
  })

  CopyToClickboard('.custom-pc-copy-to-clickboard', {
    onClick ({ $parent }) {
      $parent.classList.add('custom-pc-copy-to-clickboard_active')
    }
  })

  // для страницы сравнения: вкладки и сама логика работы страницы
  function initializeCompare (wrapper) {
    if (!wrapper.dataset.compareGridInitialized) {
      wrapper.dataset.compareGridInitialized = true
      CompareController({
        container: wrapper.querySelector('.compare-grid')
      })
    } else {
      const compare = getCompare(wrapper.querySelector('.compare-grid'))
      compare.onResize()
    }
  }

  TabManager.init({
    parentSelector: '.compare-page',
    tab: 'tabs-row__tab',
    wrapper: 'compare-page__content',
    hideOnOneTab: true,
    hideClass: 'compare-page_hide-tabs',
    onInit: initializeCompare,
    onChange (wrapper) {
      toggleCompare(false, {
        reverse: [wrapper.querySelector('.compare-grid')]
      })
      initializeCompare(wrapper)
    }
  })

  // тултипы
  tippy('.js-tippy', {
    theme: 'translucent',
    popperOptions: {
      modifiers: {
        addZIndex: {
          enabled: true,
          order: 810,
          fn: data => ({
            ...data,
            styles: {
              ...data.styles,
              zIndex: 100
            }
          })
        }
      }
    }
  })

  // тултипы
  tippy('.tooltip', {
    theme: 'tool',
    placement: 'bottom',
    arrow: roundArrow

  })

  // слайдер для табов
  SimpleSlider('.tabs-row_js-overflow', {
    slideWidth: 'auto',
    windowMinWidth: 800,
    classes: {
      slider: {
        base: 'tabs-row__slider'
      },
      col: {
        base: 'tabs-row__tab'
      },
      arrow: {
        base: 'tabs-row__arrow',
        active: 'tabs-row__arrow_active',
        left: 'tabs-row__arrow_left',
        right: 'tabs-row__arrow_right'
      }
    }
  })

  /* Валидация формы консультаций */
  const feedbackForm = document.querySelector('.feedback-form:not(.feedback-form_without-validation)')

  if (feedbackForm) {
    feedbackForm.addEventListener('submit', (e) => {
      const phoneInput = e.target.querySelector('.feedback-form__input:first-child')
      const submitButton = e.target.querySelector('.feedback-form__submit')
      const validateBlock = e.target.querySelector('.feedback-form__validation')

      if (phoneInput.value.length > 0) {
        submitButton.classList.add('feedback-form__submit_is-sended')
        submitButton.value = 'Заявка отправлена'
        validateBlock.style.display = 'none'
      } else {
        validateBlock.style.display = 'block'
        e.preventDefault()
      }
    })
  }

  // Скрытие элементов, которые не помещаются в одну строку и их отображение при открытии
  // Список производителей и моделей в шапке категории
  FitListToBlock('.link-filter__row', {
    classes: {
      item: {
        base: 'link-filter__link'
      },
      immortal: {
        base: 'link-filter__immortal',
        active: 'link-filter__immortal_active'
      },
      showOnClick: {
        base: 'link-filter__immortal'
      }
    },
    onChange ({ hiddenList, reset }) {
      const activeLinkInHiddenList = hiddenList.querySelector('.link-filter__immortal_active')
      if (activeLinkInHiddenList) {
        reset()
      }
    }
  })

  // Обычный список фильтров, которые идут друг за другом
  FitListToBlock('.bem-filter-row__filter-list_js-one-row', {
    classes: {
      item: {
        base: 'bem-filter-row__filter'
      },
      immortal: {
        base: 'bem-filter-row__filter_immortal',
        active: 'bem-filter-row__filter_immortal-active'
      },
      showOnClick: {
        base: 'bem-filter-row__filter_immortal'
      }
    },
    rowsCount: 2,
    onChange ({ hiddenList, reset }) {
      const activeLinkInHiddenList = hiddenList.querySelector('.bem-filter-row__immortal-active')
      if (activeLinkInHiddenList) {
        reset()
      }
    }
  })

  // таймер обратного отсчёта
  SimpleCountdown('.simple-countdown')

  // поиск на странице выбора города
  ListSearch('.city-select_js-search', {
    classes: {
      field: {
        base: 'city-select__field',
        active: 'city-select__field_active'
      },
      list: {
        active: 'city-select_search-in-progress'
      },
      listEl: {
        base: 'city-select__list-el',
        active: 'city-select__list-el_active'
      },
      listGroup: {
        base: 'city-select__col',
        active: 'city-select__col_active'
      },
      empty: {
        base: 'city-select__search-empty',
        active: 'city-select__search-empty_active'
      }
    }
  })

  // поиск на странице выбора бренда
  ListSearch('.brands-select_js-search', {
    classes: {
      field: {
        base: 'brands-select__field',
        active: 'brands-select__field_active'
      },
      list: {
        active: 'brands-select_search-in-progress'
      },
      listEl: {
        base: 'brands-select__list-el',
        active: 'brands-select__list-el_active'
      },
      listGroup: {
        base: 'brands-select__col',
        active: 'brands-select__col_active'
      },
      empty: {
        base: 'brands-select__search-empty',
        active: 'brands-select__search-empty_active'
      }
    }
  })

  // Слайдер новостей
  Siema.init('.row-slider_news', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider__product',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // сетка фотографий, которые при клике на них открываются по весь экран
  PhotoswipePhotoGrid()

  // фотки на странице чейнджлога
  PhotoswipePhotoGrid('.changelog-page__el', {
    classes: {
      photo: {
        base: 'changelog-page__el-content img'
      }
    }
  })

  // фильтры в каталоге
  FilterList()

  // Lazy load для изображений, способы применения https://github.com/verlok/lazyload#recipes
  new LazyLoad({
    elements_selector: '.bem-lazy',
    threshold: 400
  })

  // тултипы для сравнения/избранного
  ToolTooltip()

  ToolTooltip('.js-tool-product-cart', {
    classes: {
      contentWrapper: {
        base: 'js-tool-product-cart__content-wrapper'
      },
      clickContent: {
        base: 'js-tool-product-cart__click-content'
      }
    },
    disableOn: 800
  })

  /* Вкладки на странице вакансии */
  TabManager.init({
    parentSelector: '.vacancy-page_js-content-switch',
    tab: 'vacancy-page__tab',
    wrapper: 'vacancy-page__content'
  })

  // Переключение видимости
  Toggle('.toggle-visibility', {
    classes: {
      parent: {
        active: 'toggle-visibility_active'
      },
      handler: 'toggle-visibility__handler'
    }
  })

  Toggle('.js-toggle', {
    classes: {
      parent: {
        active: 'js-toggle_active'
      },
      handler: 'js-toggle__handler'
    }
  })

  Toggle('.bem-company-list__item', {
    classes: {
      parent: {
        active: 'bem-company-list__item_active'
      },
      handler: 'js-company-list_close'
    }
  })

  Toggle('.custom-pc-components-list', {
    classes: {
      parent: {
        active: 'custom-pc-components-list_show-hidden'
      },
      handler: 'custom-pc-components-list__show-hidden-list'
    }
  })

  Toggle('.commentary_js-show-response-form', {
    classes: {
      parent: {
        active: 'commentary_js-show-response-form-active'
      },
      handler: 'commentary__show-response-form'
    }
  })

  Toggle('.catalog-root__list-el', {
    classes: {
      parent: {
        active: 'catalog-root__list-el_active'
      },
      handler: 'catalog-root__list-el-name_mobile'
    }
  })

  Toggle('.user-panel__top', {
    classes: {
      parent: {
        active: 'user-panel__top_active'
      },
      handler: 'user-panel__name-wrap'
    },
    event: 'hover'
  })

  Toggle('.category-menu__el-show', {
    classes: {
      parent: {
        active: 'category-menu__el-show_active'
      },
      handler: 'category-menu__el-link'
    }
  })

  Toggle('.cart-order__form-row', {
    classes: {
      parent: {
        active: 'cart-order__form-row_active'
      },
      handler: 'cart-order__show-commentary'
    }
  })

  Toggle('.cart-detail__block', {
    classes: {
      parent: {
        active: 'cart-detail__block_active'
      },
      handler: 'cart-detail__show-products'
    }
  })

  Toggle('.cart-order__another-man', {
    classes: {
      parent: {
        active: 'cart-order__another-man_active'
      },
      handler: 'cart-order__another-link'
    }
  })

  ToggleChat('.js-support-chat')

  // Слайдер баннеров телефонов
  Siema.init('.brand-promo-slider', {
    selector: '.brand-promo-slider__slides',
    cloneCount: 2,
    loop: true,
    autoplay: 3200,
    markCurrent: true,
    asideSlideClickNav: true,
    checkSlidesFill: false,
    classes: {
      active: 'brand-promo-slider__slide-wrapper_active'
    }
  })

  // Показ сетки при загрузке js и добавление класса в сетку, в зависимости количества элементов
  function GridBlocks () {
    const $gridBlocks = Array.from(
      document.querySelectorAll('.brand-promo-grid')
    )
    $gridBlocks.forEach((n) => {
      n.classList.add(`brand-promo-grid_${n.children.length}`)
      n.classList.remove('brand-promo-grid_hidden')
    })
  }

  GridBlocks()

  PhotoPreview('.attached-photo-row_js-photo-preview', {
    classes: {
      element: 'attached-photo-row__el',
      photoWrapper: 'attached-photo-row__photo-wrapper',
      photo: 'attached-photo-row__photo',
      photoSwipe: 'photoswipe-photo-grid__photo'
    }
  })

  ShowFeatureTippy()

  // плашки для отображения статусов
  window.NotificationPopUp = NotificationPopUp

  /* gift-delivery */
  if (document.querySelector('.gift-delivery__close')) {
    document.querySelector('.gift-delivery__close').addEventListener('click', function () {
      document.querySelector('.gift-delivery').classList.remove('gift-delivery_active')
    })
  }

  // баннер для приложения
  NotificationBlock('.app-promo')

  // плашка для уведомлений
  NotificationBlock('.notification-alert_js', {
    interval: false,
    showForCertainOS: false,
    viewedOn: 'close',
    classes: {
      promo: {
        base: 'notification-alert_js',
        active: 'notification-alert_active'
      },
      close: {
        base: 'notification-alert__close'
      }
    }
  })

  // Клонирование элементов при нажатии на кнопку
  CloneEl.init()

  SimpleCallbackForm({
    selector: '.callback_t',
    classes: {
      btn: 'button',
      currentInput: 'input',
      inputForm: 'input_num',
      correctForm: 'correct',
      input: {
        error: 'error-input',
        hide: 'hide_form',
        show: 'show_correct'
      }
    }
  })

  AuctionValidation({
    selector: '.without_active',
    classes: {
      btn: 'button',
      accept: 'auction_validation_accept',
      error: 'auction_validation_error',
      input: 'auction_form'
    }
  })

  // закрепление левой части на корпоративной странице
  if (document.querySelector('.corp-page__info')) {
    shareToGlobal.share(
      'StickyCorporateSidebar',
      new StickySidebar('.corp-page__info', {
        topSpacing: 0,
        bottomSpacing: 0,
        minWidth: 1000,
        containerSelector: '.corp-page',
        innerWrapperSelector: '.corp-page__info-wrapper'
      })
    )
  }

  // Закрепление левой части на странице корня каталога
  new ScrollMenu('catalog-root__menu-el', 'bem-navigation-menu__list-item', 'catalog-root__list-el', {
    contentElActiveClass: false,
    trashold: 80,
    stickySidebar () {
      return new StickySidebar('.catalog-root__menu', {
        topSpacing: 10,
        bottomSpacing: 10,
        minWidth: 900,
        containerSelector: '.catalog-root',
        innerWrapperSelector: '.catalog-root__menu-el'
      })
    }
  })

  // всплывающее меню категорий
  MainMenu('.js-catalog-button', {
    classes: {
      button: {
        base: 'header__catalog-wrapper',
        active: 'header__catalog-wrapper_active'
      },
      userPanelElement: {
        base: 'user-panel__el_mobile',
        active: 'user-panel__el_mobile_active'
      },
      wrapper: 'category-menu__wrapper',
      listWrapper: 'category-menu__list-wrapper',
      elementWrapper: 'category-menu__el-wrapper',
      list: 'category-menu__list',
      listElement: {
        base: 'category-menu__el_nested',
        active: 'category-menu__el_active'
      },
      closeButton: 'category-menu__close',
      mobileTitle: 'category-menu__header-title',
      elementTitle: 'category-menu__text',
      buttonBack: 'category-menu__back'
    }
  })

  // слайдер в карточке продукта
  ProductCardSlider('.product-card__photo-slider', {
    classes: {
      photos: {
        photo: 'product-card__photo-wrapper',
        active: 'product-card__photo-wrapper_active'
      },
      dots: {
        dotWrap: 'product-card__photo-slider-dots',
        dot: 'product-card__photo-slider-dot',
        active: 'product-card__photo-slider-dot_active'
      }
    }
  })

  WindowCart()

  WindowCart('.add-goods_favorite', {
    classes: {
      close: 'add-goods__close',
      button: 'window-cart-favorite-show',
      active: 'add-goods_active'
    }
  })

  WindowCart('.add-goods_compare', {
    classes: {
      close: 'add-goods__close',
      button: 'window-cart-compare-show',
      active: 'add-goods_active'
    }
  })

  IphonePreorder('#iphone-12-preorder')

  InputCounter('.cart-el', {
    classes: {
      input: 'cart-el__input-number',
      button: {
        minus: 'cart-el__button-number_minus',
        plus: 'cart-el__button-number_plus'
      }
    }
  })

  ScrollToBlock('.total-cart', {
    classes: {
      block: 'cart-detail',
      parent: {
        active: 'total-cart_active'
      }
    }
  })

  // таймер обратного отсчета
  Countdown()

  document.body.classList.remove('no-js')
})
