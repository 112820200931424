import rangesliderJs from 'rangeslider-js'

export default function RangeSlider (
  selector = '.range-input_js',
  {
    textInput = 'range-input__text-input',
    rangeInput = 'range-input__range',
    initialInputSync = true,
    initialPlaceholderSync = false,
    onInit,
    onRangeChange
  } = {}
) {
  let $parent, $textInput, $rangeInput, rangeInstance
  const self = this

  if (typeof selector === 'string') {
    const arr = document.querySelectorAll(selector)
    arr.forEach((el) => {
      RangeSlider.apply({}, [el, arguments[1]])
    })
  } else if (typeof selector === 'object') {
    init()
  }

  function init () {
    $parent = selector
    $textInput = $parent.querySelector(`.${textInput}`)
    $rangeInput = $parent.querySelector(`.${rangeInput}`)

    const opts = { onSlide: onRangeSlide }
    if (initialInputSync) opts.onInit = onRangeInit

    rangeInstance = new rangesliderJs.RangeSlider($rangeInput, opts)

    $textInput.addEventListener('input', onInputValueChange)
    $textInput.addEventListener('blur', onInputBlur)

    onInit && onInit.call(self, externalApi())
  }

  function onRangeInit (value) {
    if (initialPlaceholderSync) {
      $textInput.placeholder = value
    } else {
      $textInput.value = value
    }
  }

  function onRangeSlide (value) {
    $textInput.value = value
    onRangeChange && onRangeChange.call(self, { value })
  }

  function onInputValueChange () {
    updateRangeValue(this.value)
  }

  function onInputBlur () {
    const num = checkInputValueRange(this)
    this.value = num
    updateRangeValue(num)
  }

  function updateRangeValue (val) {
    rangeInstance._setValue(val)
    rangeInstance._update()
  }

  function checkInputValueRange (input) {
    let num = +input.value
    num = num > +input.max ? +input.max : num
    num = num < +input.min ? +input.min : num
    return num
  }

  function externalApi () {
    return {
      parent: $parent,
      rangeInstance
    }
  }

  return externalApi()
}
