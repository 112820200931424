/*
    Копирование данных из дата-атрибута элемента при клике

    Параметры
    selector - дом нода или селектор родительского элемента-обертки
    {
      classes - объект с классам
      onClick - каллбэке, срабатывает на клике на элемент. Каллбэк получает объект с параметрами из функции externalAPI
    }
  */
import isElement from 'lodash.iselement'
export default function CopyToClickboard (
  selector = '.copy-to-clickboard',
  {
    classes,
    onClick = () => {}
  } = {}
) {
  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach((el) => {
      CopyToClickboard(el, arguments[1])
    })
  } else if (isElement(selector)) {
    init()
  }

  function init () {
    selector.addEventListener('click', onSelectorClick)
  }

  function onSelectorClick (e) {
    e.preventDefault()
    copy()
    onClick(externalAPI())
  }

  function copy () {
    const $temp = document.createElement('input')
    $temp.value = selector.dataset.copy
    document.body.appendChild($temp)
    $temp.select()
    document.execCommand('copy')
    $temp.remove()
  }

  function externalAPI () {
    return {
      $parent: selector
    }
  }

  return externalAPI()
}
