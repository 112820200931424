/*
  Компонент  используется для того, чтобы  выводить ошибку при отправки пустого input, либо информацию об успешной отправки,
  selector - селектор обёртки всех элементов,
  inputForm - элемент form,
  correctForm - место где выводится информация, при успешной отправки,
  btn - кнопка для отправки,
  input.error - активируется при отправки пустого input,
  input.hide -  скрытие form, при успешной отправки,
  input.show -  активация correctForm
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'

export default function SimpleCallbackForm ({
  selector = 'js-simple-callback-form',
  classes = {}
} = {}) {
  let $parent, $currentInput, $inputForm, $correctForm
  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      const param = { ...arguments[0] }
      param.selector = $el
      SimpleCallbackForm(param)
    })
  } else if (isElement(selector)) {
    init()
  }
  function getClasses (obj) {
    return merge(
      {
        correctForm: 'js-simple-callback-form__correct',
        inputForm: 'js-simple-callback-form__input',
        currentInput: 'js-simple-callback-form__current-input',
        btn: 'js-simple-callback-form__button',
        input: {
          error: 'js-simple-callback-form__error',
          hide: 'js-simple-callback-form__hide_form',
          show: 'js-simple-callback-form__show_correct'
        }
      },
      obj
    )
  }
  function init () {
    classes = getClasses(classes)
    $parent = selector
    $currentInput = $parent.querySelector(`.${classes.currentInput} input`)
    $inputForm = $parent.querySelector(`.${classes.inputForm}`)
    $correctForm = $parent.querySelector(`.${classes.correctForm}`)
    $parent.querySelector(`${classes.btn}`).addEventListener('click', onClick)
  }
  function error () {
    $currentInput.classList.add(classes.input.error)
    $currentInput.addEventListener('animationend', function () {
      $currentInput.classList.remove(classes.input.error)
    })
  }
  function success () {
    $inputForm.classList.add(classes.input.hide)
    $inputForm.addEventListener('animationend', function () {
      $inputForm.style.display = 'none'
      $correctForm.classList.add(classes.input.show)
    })
  }
  function onClick (event) {
    event.preventDefault()
    if (+$currentInput.value === 0) {
      error()
    } else {
      success()
    }
  }
}
