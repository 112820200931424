/*
  selector - селектор основного блока-обертки

  Объект с параметрами:
  {
    disableOn - разрешение экрана, при котором нужно отключать tippy
  }
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import tippy from 'tippy.js'
import shareToGlobal from './ShareToGlobal'

const list = []

export default function ToolTooltip (
  selector = '.js-tool-tooltip',
  { classes = {}, disableOn = false } = {}
) {
  const parent = typeof selector === 'string' ? Array.from(document.querySelectorAll(selector)) : selector

  if (parent instanceof Array) {
    if (parent.length === 0) return

    const arr = []

    parent.forEach((el) => {
      arr.push(ToolTooltip(el, arguments[1]))
    })

    return arr
  } else if (!isElement(parent) || !!parent.dataset.tooltipActivated) {
    return
  }

  list.push(externalAPI())
  parent.dataset.tooltipActivated = true

  let tippyHoverInstance, tippyClickInstance
  const cls = getClasses(classes)
  let hoverContent = parent.dataset.tooltipHoverContent
  let clickContent = parent.querySelector(`.${cls.clickContent.base}`)

  clickContent = clickContent ? clickContent.innerHTML : null

  const contentWrapper = parent.querySelector(`.${cls.contentWrapper.base}`)

  initTippy({
    hoverEl: parent,
    hoverContent: hoverContent,
    clickEl: contentWrapper,
    clickContent: clickContent
  })
  parent.addEventListener('click', onClick)
  window.addEventListener('resize', onResize)

  function getClasses (obj) {
    return merge(
      {
        contentWrapper: {
          base: 'js-tool-tooltip__content-wrapper'
        },
        clickContent: {
          base: 'js-tool-tooltip__click-content'
        }
      },
      obj
    )
  }

  function initTippy ({ hoverEl, hoverContent, clickEl, clickContent } = {}) {
    if (hoverContent) {
      tippyHoverInstance = tippy(hoverEl, {
        touch: false,
        content: hoverContent
      })
    }

    if (clickContent) {
      tippyClickInstance = tippy(clickEl, {
        interactive: true,
        allowHTML: true,
        content: clickContent,
        trigger: 'manual',
        appendTo: () => document.body,
        popperOptions: {
          modifiers: {
            addZIndex: {
              enabled: true,
              order: 810,
              fn: data => ({
                ...data,
                styles: {
                  ...data.styles,
                  width: '168px',
                  zIndex: 99
                }
              })
            }
          }
        },
        onShow () {
          if (tippyHoverInstance) {
            tippyHoverInstance.hide()
            tippyHoverInstance.disable()
          }
        },
        onHide () {
          if (tippyHoverInstance) {
            tippyHoverInstance.enable()
          }
        }
      })
    }
  }

  function onClick (e) {
    if (disableOn && window.innerWidth > disableOn) {
      tippyClickInstance && tippyClickInstance.show()
      tippyHoverInstance && tippyHoverInstance.hide()
    }
  }
  function onResize () {
    if (disableOn && window.innerWidth <= disableOn) {
      tippyClickInstance && tippyClickInstance.hide()
    }
  }

  function refresh () {
    hoverContent = parent.dataset.tooltipHoverContent
    clickContent = parent.querySelector(`.${cls.clickContent.base}`)
    clickContent = clickContent ? clickContent.innerHTML : null

    if (!clickContent && tippyClickInstance) {
      tippyClickInstance.disable()
    } else if (clickContent && tippyClickInstance) {
      tippyClickInstance.enable()
    }

    if (tippyHoverInstance) {
      tippyHoverInstance.setProps({
        content: hoverContent
      })
    }

    if (tippyClickInstance) {
      tippyClickInstance.setProps({
        content: clickContent
      })
    }
  }

  function externalAPI () {
    return {
      refresh,
      parent
    }
  }
}

function getToolTooltip (node) {
  return list.find((el) => el.parent === node)
}

shareToGlobal.share('ToolTooltip', ToolTooltip)
shareToGlobal.share('getToolTooltip', getToolTooltip)
