import shareToGlobal from './ShareToGlobal.js'

export default function TextareaDynamicHeight (selector) {
  const arr = typeof selector === 'string' ? Array.from(document.querySelectorAll(selector)) : [selector]
  if (isItMultipleElements(arr) || arr.length === 0) return

  const textarea = arr[0]
  if (hasBeenActivated(textarea)) return
  textarea.addEventListener('input', changeHeight)

  function isItMultipleElements (arr) {
    if (arr.length > 1) {
      arr.forEach((el) => TextareaDynamicHeight(el))
      return true
    };
  }

  function changeHeight () {
    textarea.style.height = '0px'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  function hasBeenActivated (el) {
    if (el.dataset.dynamicTextareaActivated) {
      return true
    } else {
      el.dataset.dynamicTextareaActivated = true
      return false
    }
  }
}

shareToGlobal.share('TextareaDynamicHeight', TextareaDynamicHeight)
