import shareToGlobal from './ShareToGlobal.js'
import hoverintent from 'hoverintent'

/*
  1. Класс у элемента вызывающего окно .bem-modal-show
  2. Название окна в аттрибуте data-modal-name (этот же аттрибут должен быть у окна)
  3. Если у окна есть аттрибут data-modal-type="float" можно указать data-modal-direction для вызывающего элемента со значениями left, top, bottom, right
  4. Для типа float доступны аттрибуты data-modal-x-offset и data-modal-y-offset. Они добавляют смещение к окончательным кординатам. Указываются у вызывающего элемента
  5. Для типа full-scren окно выводится во весь экран и появляется затемнение фона.
*/

const modalList = []

export default class Modal {
  constructor (
    target,
    opts = {
      offsetToModal: 10,
      offsetToDocument: 20,
      offsetXToTarget: 0,
      offsetYToTarget: 0,
      modalType: 'float',
      modalDirection: 'top',
      hoverInterval: 0,
      modalIgnoreViewport: false,
      onEnable: false,
      onDisable: false
    }
  ) {
    try {
      if (this.hasBeenActivated(target)) return

      modalList.push(this)
      this.target = target
      this.initialized = false
      this.offset_to_modal = opts.offsetToModal
      this.offset_to_document = opts.offsetToDocument
      this.offset_x_to_target = opts.offsetXToTarget
      this.offset_y_to_target = opts.offsetYToTarget
      this.modal_type = opts.modalType
      this.modal_direction = opts.modalDirection
      this.hoverInterval = +this.target.dataset.modalHoverTimeout || opts.hoverInterval
      this.modal_ignore_viewport = !!this.target.dataset.modalIgnoreViewport || opts.modalIgnoreViewport
      this._showModal = this.showModal.bind(this)
      this._hideModal = this.hideModal.bind(this)
      this._isOutOfModal = this.isOutOfModal.bind(this)
      this.onEnable = opts.onEnable
      this.onDisable = opts.onDisable
      this.setAppropriateEvent(this.target)
    } catch (e) {
      console.error('Ошибка инициализации класса Modal', e)
    }
  }

  hasBeenActivated (el) {
    if (el.dataset.modalActivated) {
      return true
    } else {
      el.dataset.modalActivated = true
      return false
    }
  }

  setAppropriateModal (target) {
    let modal
    const modalName = target.dataset.modalName
    if (modalName) {
      modal = document.querySelector(`.bem-modal[data-modal-name='${target.dataset.modalName}']`)
    } else {
      modal = target.querySelector('.bem-modal')
      document.querySelector('body').appendChild(modal)
    }
    return modal
  }

  setAppropriateEvent () {
    switch (this.target.dataset.event) {
      case 'hover':
        hoverintent(this.target, () => this.showModal(), () => this.showModal())
        break
      case 'passive':
        break
      default:
        this.target.addEventListener('click', this._showModal)
        break
    }
  }

  setup () {
    if (this.initialized === true) return
    this.targetDimension = this.getTargetDimension()
    this.x_offset = parseInt(this.target.dataset.modalXOffset) || this.offset_x_to_target
    this.y_offset = parseInt(this.target.dataset.modalYOffset) || this.offset_y_to_target
    this.modal = this.setAppropriateModal(this.target)
    this.modal_type = this.getModalType()
    this.active_class = this.getActiveClass()
    this.direction = this.target.dataset.modalDirection || this.modal_direction
    this.initialized = true
  }

  getModalType () {
    const type = {}
    if (this.target.dataset.modalViewportType) {
      const viewportType = this.target.dataset.modalViewportType.split(';')
      type.relative = {}
      viewportType.forEach((el) => {
        const arr = el.split(':')
        type.relative[arr[0]] = arr[1]
      })
    }

    type.main = this.modal.dataset.modalType || this.modal_type
    return type
  }

  getActiveClass () {
    const classList = {}
    if (this.target.dataset.modalViewportClass) {
      const viewportClass = this.target.dataset.modalViewportClass.split(';')
      classList.relative = {}
      viewportClass.forEach((el) => {
        const arr = el.split(':')
        classList.relative[arr[0]] = arr[1].split(',')
      })
    }

    if (this.modal.dataset.modalClass) classList.main = this.modal.dataset.modalClass.split(',')
    return classList
  }

  getCurrentViewportProp (prop) {
    if (prop.relative) {
      const list = Object.keys(prop.relative)
      let diff, current
      list.forEach((el) => {
        if (+el > window.innerWidth) {
          const d = +el - window.innerWidth
          if (d < diff || diff === undefined) {
            current = el
            diff = d
          }
        }
      })

      if (current) return prop.relative[current]
    }

    return prop.main
  }

  getTargetDimension () {
    const rect = this.target.getBoundingClientRect()
    return {
      top: rect.top + window.scrollY,
      bottom: rect.bottom + window.scrollY,
      left: rect.left,
      right: rect.right
    }
  }

  isOutOfModal (e) {}

  showModal (e) {
    this.setup()
    this.toggleActiveClass('add')

    this.modal_width = this.modal.offsetWidth
    this.modal_height = this.modal.offsetHeight
    this.current_type = this.getCurrentViewportProp(this.modal_type)

    // окон может быть несколько типов
    // плавающее, появляется рядом с вызывающим элементом
    if (this.current_type === 'float') {
      const coordinates = this.getModalCoordinates()
      this.modal.style.left = `${coordinates.left}px`
      this.modal.style.top = `${coordinates.top}px`
      if (this.target.dataset.event === 'hover') {
        document.addEventListener('mousemove', this._hideModal)
      } else {
        document.addEventListener('click', this._hideModal)
      }
    } else if (this.current_type === 'full-screen') {
      // в полный экран, черное
      // если окно уже было активировано - значит оно уже лежит внутри blackout'a
      // это на случай, если окно вызывает другой элемент, не тот что впервые.
      // То есть окно уже будет активно, но этот элемент не знает о blackout'e
      if (this.modal.dataset.wasActive) {
        this.blackout = this.modal.closest('.bem-blackout')
      } else {
        // если окно не было активировано blackout создается вокруг и окно ложится внутрь
        this.blackout = document.createElement('div')
        this.blackout.classList.add('bem-blackout')
        this.modal.parentNode.insertBefore(this.blackout, this.modal)
        this.blackout.appendChild(this.modal)
        this.modal.dataset.wasActive = true
      }
      // создание элемента и добавление класса разнес по разным фреймам,
      // иначе анимации не будет т.к. всё отрендерится в один проход
      const self = this
      window.requestAnimationFrame(() => {
        self.blackout.classList.add('bem-blackout_active')
      })
      this.blackout.addEventListener('click', this._hideModal)
    } else if (this.current_type === 'full-screen-white') {
      // в полный, белое
      if (this.modal.dataset.wasActive) {
        this.blackout = this.modal.closest('.white-overlay')
      } else {
        this.blackout = document.createElement('div')
        this.blackout.classList.add('white-overlay')
        this.modal.parentNode.insertBefore(this.blackout, this.modal)
        this.blackout.appendChild(this.modal)
        this.modal.dataset.wasActive = true
      }
      const self = this
      window.requestAnimationFrame(() => {
        self.blackout.classList.add('white-overlay_active')
      })
      this.blackout.addEventListener('click', this._hideModal)
      this.close_button = this.getCloseButton()
      this.close_button && this.close_button.forEach((el) => {
        el.addEventListener('click', this._hideModal)
      })
    }

    this.onEnable && this.onEnable()
    if (e) e.preventDefault()
  }

  getCloseButton () {
    return [
      ...this.modal.querySelectorAll('.bem-modal__close'),
      ...this.modal.querySelectorAll('.bem-modal__js-close')
    ]
  }

  toggleActiveClass (action) {
    this.current_active_class = this.getCurrentViewportProp(this.active_class)
    if (this.current_active_class) {
      this.modal.classList[action](...this.current_active_class)
    }

    const self = this
    window.requestAnimationFrame(() => self.modal.classList[action]('bem-modal_active'))
  }

  hideModal (e, { force = false } = {}) {
    // при клике на ссылку закрытия не перемещать юзера вврх
    if (e && this.isCloseButtonClosest(e.target)) e.preventDefault()

    // проверяем, нужно ли скрывать окно
    if (force !== true && e) {
      const shouldHide = this.shouldHide(e)
      if (!shouldHide) return
    }

    this.clearAfterModalHide(this.current_type)
    this.toggleActiveClass('remove')
    this.onDisable && this.onDisable()
  }

  isCloseButtonClosest (el) {
    return el.closest('.bem-modal__close, .bem-modal__js-close')
  }

  setOnDisable (fn) {
    this.onDisable = fn
  }

  setOnEnable (fn) {
    this.onEnable = fn
  }

  shouldHide (e) {
    return this.target.dataset.event === 'hover' ? this.shouldHideHover(e) : this.shouldHideClick(e)
  }

  shouldHideClick (e) {
    if (
      e.target.closest('.bem-modal-show') || (e.target.closest('.bem-modal') && !this.isCloseButtonClosest(e.target))
    ) {
      return false
    } else {
      return true
    }
  }

  shouldHideHover (e) {
    const stillInsideModal = e.target.closest('.bem-modal')
    const stillOnTarget = e.target.closest('.bem-modal-show') === this.target
    const stillInside = stillInsideModal || stillOnTarget
    if (this.hoverInterval !== 0 && !stillInside && !this.hideModalTimer) {
      this.hideModalTimer = window.setTimeout(
        this.hideModal.bind(this, null, {
          force: true
        }),
        this.hoverInterval
      )
    } else if (this.hideModalTimer && stillInside) {
      window.clearTimeout(this.hideModalTimer)
      this.hideModalTimer = null
    } else if (!stillInside && !this.hideModalTimer) {
      return true
    }
    return false
  }

  clearAfterModalHide (type) {
    if (type === 'float') {
      if (this.target.dataset.event === 'hover') {
        document.removeEventListener('mousemove', this._hideModal)
      } else {
        document.removeEventListener('click', this._hideModal)
      }
    } else if (type === 'full-screen') {
      this.blackout.classList.remove('bem-blackout_active')
      this.blackout.removeEventListener('click', this._hideModal)
    } else if (type === 'full-screen-white') {
      this.blackout.classList.remove('white-overlay_active')
      this.blackout.removeEventListener('click', this._hideModal)
      this.close_button.forEach((el) => el.removeEventListener('click', this._hideModal))
    }
  }

  getModalCoordinates () {
    let { left, top } = this.getModalCoordinatesRelativeToFitData()

    if (!left) {
      left = this.getHorizontalCoordinates()
    } else {
      top = this.getVerticalCoordinates()
    }

    if (this.x_offset) left += this.x_offset
    if (this.y_offset) top += this.y_offset

    return {
      left,
      top
    }
  }

  getModalCoordinatesRelativeToFitData () {
    const rect = this.target.getBoundingClientRect()
    const MODAL_FIT = this.getModalFit(rect)
    const MODAL_POSITION = this.getModalPositions(rect)
    let left
    let top

    switch (this.direction) {
      case 'left':
        if (MODAL_FIT.left) {
          left = MODAL_POSITION.left
        } else if (MODAL_FIT.right) {
          left = MODAL_POSITION.right
        } else {
          left = 20
        }
        break
      case 'right':
        if (MODAL_FIT.right) {
          left = MODAL_POSITION.right
        } else if (MODAL_FIT.left) {
          left = MODAL_POSITION.left
        } else {
          left = 20
        }
        break
      case 'top':
        if (MODAL_FIT.top) {
          top = MODAL_POSITION.top
        } else if (MODAL_FIT.bottom) {
          top = MODAL_POSITION.bottom
        } else {
          top = 20
        }
        break
      case 'bottom':
        if (MODAL_FIT.bottom) {
          top = MODAL_POSITION.bottom
        } else if (MODAL_FIT.top) {
          top = MODAL_POSITION.top
        } else {
          top = 20
        }
        break
    }

    return { left, top }
  }

  getModalFit (rect) {
    if (this.modal_ignore_viewport) {
      return {
        top: true,
        right: true,
        bottom: true,
        left: true
      }
    } else {
      return {
        top: rect.top - this.modal_height > this.offset_to_document,
        right: window.innerWidth - rect.right - this.modal_width > this.offset_to_document,
        bottom: window.innerHeight - rect.bottom - this.modal_height > this.offset_to_document,
        left: rect.left - this.modal_width > this.offset_to_document
      }
    }
  }

  getModalPositions (rect) {
    return {
      top: rect.top - this.modal_height + window.scrollY - this.offset_to_modal,
      right: rect.right + this.offset_to_modal,
      bottom: rect.bottom + window.scrollY + this.offset_to_modal,
      left: rect.left - this.modal_width - this.offset_to_modal
    }
  }

  getHorizontalCoordinates () {
    return this.targetDimension.left + this.modal_width > window.innerWidth - 20
      ? window.innerWidth - 20 - this.modal_width
      : this.targetDimension.left
  }

  getVerticalCoordinates () {
    return this.targetDimension.top + this.modal_height > window.innerHeight + window.scrollY - 20
      ? window.innerHeight + window.scrollY - this.modal_height - 20
      : this.targetDimension.top
  }
}

Modal.init = function (selector) {
  const arr = document.querySelectorAll(selector)
  for (let i = 0; i < arr.length; i++) {
    new Modal(arr[i])
  }
}

Modal.getModal = function (selector) {
  const target = typeof selector === 'string' ? document.querySelector(selector) : selector
  const modal = modalList.find((el) => el.target === target)
  return modal
}

shareToGlobal.share('Modal', Modal)
