import merge from 'lodash.merge'
import isElement from 'lodash.iselement'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

export default function PhotoswipePhotoGrid (
  container = '.photoswipe-photo-grid_js',
  { classes = {}, photoswipeOption = {} } = {}
) {
  container = getContainerNode(container)
  if (container instanceof Array && container.length > 0) {
    container.forEach((el) => {
      PhotoswipePhotoGrid(el, arguments[1])
    })
    return
  }

  if (!isElement(container)) return

  const cls = getClasses(classes)
  photoswipeOption = getPhotoswipeOptions(photoswipeOption)

  const photoArr = Array.from(container.querySelectorAll(`.${cls.photo.base}`))
  if (photoArr.length === 0) return
  photoArr.forEach((photo) => photo.addEventListener('click', onPhotoClick))

  function getContainerNode (el) {
    if (typeof el === 'string') {
      return Array.from(document.querySelectorAll(container))
    } else {
      return container
    }
  }

  function getClasses (obj) {
    return merge(
      {
        photo: {
          base: 'photoswipe-photo-grid__photo'
        }
      },
      obj
    )
  }

  function getPhotoswipeOptions (obj) {
    return merge(
      {
        getThumbBoundsFn (index) {
          const thumbnail = photoArr[index]
          const pageYScroll = window.pageYOffset
          const rect = thumbnail.getBoundingClientRect()

          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          }
        },
        shareEl: false,
        closeElClasses: ['p', 'h3', 'additional-text', 'item', 'caption', 'zoom-wrap', 'ui', 'top-bar', 'img']
      },
      obj
    )
  }

  function parseData (nodeArr) {
    const data = []
    nodeArr.forEach((el) => {
      let src
      if (el.nodeName === 'IMG') {
        src = el.src
      } else {
        src = getComputedStyle(el)
          .backgroundImage.match(/\((.*?)\)/)[1]
          .replace(/('|")/g, '')
      }

      data.push({
        src: el.dataset.src ? el.dataset.src : src,
        msrc: src,
        w: el.dataset.photoWidth ? el.dataset.photoWidth : el.naturalWidth,
        h: el.dataset.photoHeight ? el.dataset.photoHeight : el.naturalHeight
      })
    })

    return data
  }

  function onPhotoClick (e) {
    const pswp = document.querySelector('.pswp')
    if (!pswp) { throw Error('Ошибка, не удалось найти .pswp элемент, который необходим для активации слайдера во весь экран') }
    const photoSwipe = new PhotoSwipe(pswp, PhotoSwipeUIDefault, parseData(photoArr), {
      ...photoswipeOption,
      index: photoArr.indexOf(this)
    })
    photoSwipe.init()
  }
}
