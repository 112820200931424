class ShareToGlobal {
  constructor () {
    if (window.shared) {
      this.data = window.shared
    } else {
      this.data = {}
      window.shared = this.data
    }
  }

  share (name, func) {
    this.data[name] = func
  }
}

const shareToGlobal = new ShareToGlobal()
export default shareToGlobal
