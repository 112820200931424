/* показ содержания после клика на элемент и последующее скрытие элемента */
export default function ShowHidden (
  selector = '.bem-show-hidden',
  { activeDisplay = 'block' } = {}
) {
  selector = typeof selector === 'string' ? Array.from(document.querySelectorAll(selector)) : selector
  if (selector instanceof Array) {
    selector.forEach((el) => ShowHidden(el))
  } else {
    init()
  }

  function init () {
    selector.addEventListener('click', onClick)
  }

  function onClick (e) {
    e.preventDefault()
    const blockName = selector.dataset.block
    const hideTarget = !!selector.dataset.hideTarget
    if (hideTarget) {
      selector.removeEventListener('click', onClick)
      selector.style.display = 'none'
    }

    const $block = document.querySelector(
      `.bem-hidden-block[data-block="${blockName}"]`
    )
    $block.style.display = activeDisplay
  }
}
