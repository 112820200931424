import isElement from 'lodash.iselement'
import merge from 'lodash.merge'
import hoverintent from 'hoverintent'

export default function Toggle (
  parent = '.js-toggle',
  {
    classes, event = 'click'
  } = {}
) {
  let $toggleElementArr

  if (typeof parent === 'string') {
    const arr = Array.from(document.querySelectorAll(parent))
    arr.forEach((el) => {
      Toggle.apply({}, [el, arguments[1]])
    })
  } else if (isElement(parent)) {
    init()
  }

  function getClasses (obj) {
    return merge({
      parent: {
        base: 'js-toggle__el',
        active: 'js-toggle__el_active'
      },
      handler: 'js-toggle-handler'
    },
    obj
    )
  }

  function init () {
    classes = getClasses(classes)
    $toggleElementArr = Array.from(parent.querySelectorAll(`.${classes.handler}`))
    const param = parent.dataset.event ? parent.dataset.event : event
    if (param === 'hover') {
      $toggleElementArr.forEach((item) => {
        hoverintent(item, onMouseOver, onMouseOut)
      })
    } else if (param === 'click') {
      $toggleElementArr.forEach((item) => {
        item.addEventListener('click', onClick)
      })
    }
  }

  function onClick (e) {
    e.preventDefault()
    parent.classList.toggle(classes.parent.active)
  }

  function onMouseOver () {
    parent.classList.add(classes.parent.active)
  }

  function onMouseOut () {
    parent.classList.remove(classes.parent.active)
  }
}
