import isElement from 'lodash.iselement'
import merge from 'lodash.merge'

export default function InputCounter (
  selector = '.js-input-counter',
  {
    classes = {}
  } = {}
) {
  let cls
  let $parent
  let $inputs
  let $buttonMinus
  let $buttonPlus
  let value = 1
  let min
  let max

  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      InputCounter($el, arguments[1])
    })
  } else if (isElement(selector) && !selector.dataset.productCardSliderActive) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    $parent = selector
    $inputs = $parent.querySelectorAll(`.${cls.input}`)
    $buttonMinus = $parent.querySelectorAll(`.${cls.button.minus}`)
    $buttonPlus = $parent.querySelectorAll(`.${cls.button.plus}`)
    addListener()
  }
  function addListener () {
    $inputs.forEach($el => {
      min = $el.min
      max = $el.max
      $el.addEventListener('input', inputHandler)
    })
    $buttonMinus.forEach($el => {
      $el.addEventListener('click', minus)
    })
    $buttonPlus.forEach($el => {
      $el.addEventListener('click', plus)
    })
  }

  function inputHandler ({ target }) {
    value = validation(target.value)
    inputValue()
  }

  function validation (count) {
    if (count >= min && count.length <= max.length) {
      return count
    } else if (count < 1) { return 1 }
    return value
  }

  function buttonDisabled () {
    if (value > min) {
      $buttonMinus.forEach($el => {
        $el.disabled = false
      })
    } else {
      $buttonMinus.forEach($el => {
        $el.disabled = true
      })
    }
    if (value < max) {
      $buttonPlus.forEach($el => {
        $el.disabled = false
      })
    } else {
      $buttonPlus.forEach($el => {
        $el.disabled = true
      })
    }
  }

  function minus () {
    value--
    inputValue()
  }

  function plus () {
    value++
    inputValue()
  }

  function inputValue () {
    $inputs.forEach($el => {
      $el.value = value
    })
    buttonDisabled()
  }

  function getClasses (obj) {
    return merge(
      {
        input: 'js-input-counter__input',
        button: {
          minus: 'js-input-counter__minus',
          plus: 'js-input-counter__plus'
        }
      },
      obj
    )
  }
}
