/*
  Меню которое активирует пункты внутри себя относительно других элементов на странице при скролле.

  Каждый пункт меню и отслеживаемый им элемент нужно "соединить" аттрибутом data, по стандарту data-scroll-menu (можно изменить через опции).
  То есть для одного элемента меню и для соответствующего ему элемента на странице нужно поставить этот аттрибут с одинаковым (уникальным) значением

  Обязательные параметры:
  menuClass класс для меню внутри которого стоят элементы-переключатели
  menuElClass класс элемента-переключателя внутри меню
  contentElClass класс для стороннего элемента на странице положение которого будем отслеживать

  Опциональные
  menuElActiveClass класс для активированного элемента меню;
  contentElActiveClass класс для активированного элемента на странице;
  dataAttribute (в camelCase пример scrollMenu) дата-аттрибут через значения которого соединяются элементы меню и элементы на странице;
  stickySidebar (function) активация плавающего меню через функцию, внутри функции нужно активировать
  конструктор StickySidebar https://github.com/abouolia/sticky-sidebar и вернуть его;
  trashold (в px) отступ от верхней границы окна до элемента на странице при котором он будет активироваться;
*/

export default class ScrollMenu {
  constructor (
    menuClass,
    menuElClass,
    contentElClass, {
      menuElActiveClass = `${menuElClass}_active`,
      contentElActiveClass = `${contentElClass}_active`,
      dataAttribute = 'scrollMenu',
      stickySidebar,
      trashold = 50
    } = {}
  ) {
    try {
      this.menuClass = menuClass
      this.$menu = document.querySelector(`.${this.menuClass}`)
      if (!this.$menu) return

      this.contentElClass = contentElClass
      this.contentElActiveClass = contentElActiveClass
      this.$contentEls = Array.from(document.querySelectorAll(`.${this.contentElClass}`))
      this.$currentContentEl = document.querySelector(`.${this.contentElActiveClass}`)

      this.menuElClass = menuElClass
      this.menuElActiveClass = menuElActiveClass
      this.$menuEls = Array.from(document.querySelectorAll(`.${this.menuElClass}`))
      this.$currentMenuEl = document.querySelector(`.${this.menuElActiveClass}`)

      this.dataAttribute = dataAttribute
      this.trashold = trashold
      if (stickySidebar) this.stickySidebar = stickySidebar()

      this.detectAppropriateMenuEl()
      window.addEventListener('scroll', this.detectAppropriateMenuEl.bind(this))
      this.$menu.addEventListener('click', this.onMenuClick.bind(this))
    } catch (e) {
      console.error('Ошибка инициализации плавающего меню', e)
    }
  }

  detectAppropriateMenuEl () {
    const currentScrollOffset = window.scrollY
    const el = this.$contentEls.find(
      el => el.offsetTop + el.offsetHeight - this.trashold > currentScrollOffset
    )
    this.toggleTo(el.dataset[this.dataAttribute], {
      content: el
    })
  }

  toggleTo (name, {
    menu,
    content
  } = {}) {
    this.toogleCurrentElsActiveClass('remove')
    this.toggleMenuTo(name, menu)
    this.toggleContentTo(name, content)
    this.toogleCurrentElsActiveClass('add')
  }

  toggleMenuTo (name, el) {
    this.$currentMenuEl = el || this.$menuEls.find(el => el.dataset[this.dataAttribute] === name)
  }

  toggleContentTo (name, el) {
    this.$currentContentEl = el || this.$contentEls.find(el => el.dataset[this.dataAttribute] === name)
  }

  toogleCurrentElsActiveClass (action) {
    this.toggleCurrentMenuActiveClass(action)
    this.toggleCurrentContentActiveClass(action)
  }

  toggleCurrentMenuActiveClass (action) {
    this.$currentMenuEl &&
      this.menuElActiveClass &&
      this.$currentMenuEl.classList[action](this.menuElActiveClass)
  }

  toggleCurrentContentActiveClass (action) {
    this.$currentContentEl &&
      this.contentElActiveClass &&
      this.$currentContentEl.classList[action](this.contentElActiveClass)
  }

  onMenuClick (e) {
    const el = e.target.closest(`.${this.menuElClass}`)
    if (!el && el.classList.contains(this.menuElActiveClass)) return

    const name = el.dataset[this.dataAttribute]
    this.toggleMenuTo(name)
    this.toggleContentTo(name)
    this.$currentContentEl.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}
