/*
Компонент отвечающий за показ/скрытие пароля у полей ввода

Принимает в качестве аргумента объект с полями:
field - класс элемента-оболочки, включающая в себя все остальные элементы. Можно передавать класс или DOM ноду
input - класс указывающий на инпут
button - класс указывающий на кнопку, которая переключает видимость

Опциональные:
buttonActiveClass - класс который добавится к кнопке после активации
inputActiveClass - класс который добавится к инпуту после активации
group - идентификатор группы, если для нескольких элементов поставить один идентификтор, они будут переключаться вместе

Внешний интерфейс доступный после инициализации конструктора:
group - ready only значение идентификатора группы
change() - метод, который меняет состояние поля. В качестве аргумента нужно передать новое значение false или true
*/

const list = {}

export default function TogglePasswordVisibility ({
  field = 'toggle-password',
  input = 'toggle-password__input',
  button = 'toggle-password__button',
  buttonActiveClass = button + '_active',
  inputActiveClass = input + '_active',
  group
} = {}) {
  let state = false

  try {
    field = typeof field === 'string' ? document.querySelectorAll(`.${field}`) : field

    if (!field || field.length === 0) {
      return
    } else if (field.length > 1) {
      field.forEach((e) => new TogglePasswordVisibility({
        field: e,
        input,
        button,
        buttonActiveClass
      }))

      return
    } else if (field.length === 1) {
      field = field[0]
    }

    input = field.querySelector(`.${input}`)

    if (!input) throw Error('Не получилось найти инпут для поля скрытия пароля')
    button = field.querySelector(`.${button}`)

    if (!button) throw Error('Не получилось найти кнопку для поля скрытия пароля')
    button.addEventListener('click', changeState)

    group = group || field.dataset.togglePasswordGroup

    if (group) {
      if (!list[group]) list[group] = []
      list[group].push(api())
    }
  } catch (e) {
    console.error(e)
  }

  function changeState () {
    state = !state
    changeGroupState(group, state)
    state ? enable() : disable()
  }

  function change (newState) {
    state = newState
    state ? enable() : disable()
  }

  function enable () {
    toggleClasses('add')
    input.type = 'text'
  }

  function disable () {
    toggleClasses('remove')
    input.type = 'password'
  }

  function toggleClasses (action) {
    button.classList[action](buttonActiveClass)
    input.classList[action](inputActiveClass)
  }

  function changeGroupState (group, state) {
    if (list[group]) list[group].forEach((el) => el.change(state))
  }

  function api () {
    return {
      group,
      change
    }
  }
}
