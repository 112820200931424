/*
  Проверка вмещается ли горизонтальный список в блок при нужном кол-ве строк,
  если не влезает есть два варианта:
  1. Появляется кнопка Показать всё при клике на которую показываются скрытые пункты
  2. Список просто обрезается и скрытые пункты никак нельзя открыть,
  опционально можно добавить заглушку, например, обычную ссылку Все »
  которая будет появляться если пункты не влезли
  Функция принимает следующие параметры:
  parent: класс списка который нужно проверять
  {
    classes = {
      item: {
        base: класс элемента внутри списка
      },
      immortal: {
        base: класс элементов которые появляются когда список обрезается (опционально)
        active: класс элемента, который добавляет при клике
      },
      showOnClick: {
        base: класс элемента при клике на который нужно показывать скрытые пункты (опционально, если указан элементы появляются при клике)
      }
    }
    rowsCount: максимальное кол-во строк (опционально, стандартно 1)
    passive: не активировать фильтры автоматически при создании функции, чтобы потом активировать вручную
    toggleType: определеляет какой будет тип у кнопки. Поддерживается один тип - toggle, если ничего не передано,
    то, кнопка скроется после раскрытия списка фильтров
    onChange: каллбэк, которая срабатывает при пересчете списка (опционально)
  }
*/
import isElement from 'lodash.iselement'
import merge from 'lodash.merge'
import { getWidthWithMargin } from './getWidthWithMargin'
import isMobile from './isMobile'
import fastdom from 'fastdom'
import ShareToGlobal from './ShareToGlobal'

const list = []

export default function FitListToBlock (parent = '.link-filter__row',
  {
    classes = {},
    rowsCount,
    passive,
    toggleType,
    onChange = () => {}
  } = {}) {
  let cls, immortals, itemSelector, isPassive, previousWindowWidth, isActive, immortalsWidth, listWidth, hiddenList, items, showOnClick

  if (typeof parent === 'string') {
    const arr = Array.from(document.querySelectorAll(parent))
    arr.forEach((el) => {
      FitListToBlock(el, arguments[1])
    })
  } else if (isElement(parent) && !parent.dataset.rowsWasActive) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    parent.dataset.rowsWasActive = true
    immortals = parent.querySelectorAll(`.${cls.immortal.base}`)
    rowsCount = parseInt(parent.dataset.rowsCount) || rowsCount || 1
    itemSelector = `.${cls.item.base}:not(.${cls.immortal.base})`
    isPassive = !!parent.dataset.rowsPassive || passive || false
    previousWindowWidth = window.innerWidth
    toggleType = parent.dataset.toggle || toggleType
    isActive = false

    showOnClick = parent.querySelector(`.${cls.showOnClick.base}`)
    if (showOnClick) {
      showOnClick.addEventListener('click', onClickShowHiddenList)
      createHiddenList()
    }

    if (!isPassive) fastdom.measure(() => update())
    list.push(externalAPI())
  }

  function getClasses (obj) {
    return merge({
      item: {
        base: 'link-filter__link'
      },
      immortal: {
        base: 'link-filter__immortal',
        active: 'link-filter__immortal_active'
      },
      showOnClick: {
        base: 'link-filter__immortal'
      }
    }, obj)
  }

  function getItemsData (wrapper, itemSelector) {
    const filterItems = Array.from(wrapper.querySelectorAll(itemSelector))
    const arr = filterItems.map(el => ({ width: getWidthWithMargin(el), el }))
    return arr
  }

  function calculateProps () {
    immortalsWidth = isMobile() ? 0 : getImmortalsWidth()
    listWidth = parent.offsetWidth
  }

  function getImmortalsWidth () {
    let width = 0
    immortals.forEach(item => {
      width += getWidthWithMargin(item)
    })
    return width
  }

  function createHiddenList () {
    hiddenList = document.createElement('div')
    hiddenList.style.display = 'none'
    parent.appendChild(hiddenList)
  }

  function update () {
    window.addEventListener('resize', onResize)
    reset()
    items = getItemsData(parent, itemSelector)
    window.requestAnimationFrame(() => {
      calculateProps()
      render()
    })
  }

  // Проверяем, изменилась ли ширина окна при ресайзе, если нет - ничего не делаем.
  // Это нужно, чтобы исключить активацию события при скрытии/появлении адрессбара в сафари
  function onResize () {
    if (previousWindowWidth !== window.innerWidth && !isActive) {
      update()
      previousWindowWidth = window.innerWidth
    }
  }

  function render () {
    let currentRow = 1
    let currentRowWidth = 0

    for (let i = 0; i < items.length; i++) {
      currentRowWidth += items[i].width
      if (
        currentRow === rowsCount && currentRowWidth > listWidth - immortalsWidth
      ) {
        hideListItem(items[i].el)
      } else if (currentRowWidth > listWidth) {
        currentRow += 1
        currentRowWidth = 0
        i -= 1
      }
    }

    if (listWidth >= currentRowWidth) {
      showOnClick.style.display = 'none'
    }
    onChange(externalAPI())
  }

  function hideListItem (item) {
    if (showOnClick) {
      hiddenList.appendChild(item)
    } else {
      item.style.display = 'none'
    }
  }

  function reset () {
    if (showOnClick) {
      moveItemsFromHiddenList()
      immortals.forEach((item) => {
        parent.appendChild(item)
      })
    } else {
      items.forEach((item) => {
        item.style.display = 'block'
      })
    }
  }
  function onClickShowHiddenList (e) {
    e.preventDefault()
    if (isActive) {
      render()
      update()
    } else {
      moveItemsFromHiddenList()
      if (!toggleType) {
        showOnClick.style.display = 'none'
        window.removeEventListener('resize', onResize)
      }
    }
    showOnClick.classList.toggle(cls.immortal.active)
    isActive = !isActive
  }

  function moveItemsFromHiddenList () {
    while (hiddenList.childNodes.length > 0) {
      parent.appendChild(hiddenList.childNodes[0])
    }
  }

  function externalAPI () {
    return {
      parent,
      hiddenList,
      reset,
      update
    }
  }
  return externalAPI()
}

function getFitListToBlock (selector) {
  const node = typeof selector === 'string' ? document.querySelector(selector) : selector
  return list.find((el) => el.parent === node)
}

function updateFitListToBlock () {
  list.forEach((el) => el.update())
}

ShareToGlobal.share('FitListToBlock', FitListToBlock)
ShareToGlobal.share('getFitListToBlock', getFitListToBlock)
ShareToGlobal.share('updateFitListToBlock', updateFitListToBlock)
