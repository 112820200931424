/*
  Компонент  используется для того, чтобы  выводить ошибку при отправки пустого input, либо информацию об успешной отправки,
  selector - селектор обёртки всех элементов,
  input - элемент input для ввода текста,
  btn - кнопка для отправки,
  accept - информирование при усешной отправки
  error - информирование об ошибке
*/

import merge from 'lodash.merge'
import isElement from 'lodash.iselement'

export default function AuctionValidation ({
  selector = 'js-auction-validation',
  classes = {}
} = {}) {
  let $parent, $accept, $error, $input
  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      const param = { ...arguments[0] }
      param.selector = $el
      AuctionValidation(param)
    })
  } else if (isElement(selector)) {
    init()
  }
  function getClasses (obj) {
    return merge(
      {
        accept: 'js-auction-validation__accept',
        error: 'js-auction-validation__error',
        input: 'js-auction-validation__form',
        btn: 'js-auction-validation__button'
      },
      obj
    )
  }
  function init () {
    classes = getClasses(classes)
    $parent = selector
    $accept = $parent.querySelector(`.${classes.accept}`)
    $error = $parent.querySelector(`.${classes.error}`)
    $input = $parent.querySelector(`.${classes.input}`)
    $parent.querySelector(classes.btn).addEventListener('click', validation)
  }
  function error () {
    $accept.style.display = 'none'
    $error.style.display = 'block'
  }
  function success () {
    $error.style.display = 'none'
    $accept.style.display = 'block'
    $input.setAttribute('disabled', 'true')
  }
  function validation () {
    if ($input.value.length > 0) {
      success()
    } else {
      error()
    }
  }
}
