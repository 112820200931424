/*
  selector - селектор основного блока-обертки

  Объект с параметрами:
  {
    side - сторона показа tippy
    identifyer - уникальный идентификатор
    count - кол-во раз появления tippy
    title - заголовок tippy
    textContent - основной контент tippy
    classTippy - класс для изменения стилей tippy
    timeActive - кол-во миллисекунд работы tippy
    strategy - скрывает tippy через click/time
    interval - кол-во дней через которые будет вызываться функция
  }
*/

import tippy from 'tippy.js'
import isElement from 'lodash.iselement'
import ConditionsView from './ConditionsView'

export default function ShowFeatureTippy (
  selector = '.js-show-feature',
  {
    side = 'top',
    identifyer = false,
    count = 1,
    title = 'Новая функция',
    textContent = false,
    classTippy = 'show-feature',
    timeActive = 5000,
    strategy = 'time',
    interval = 7
  } = {}
) {
  let tippyInstance
  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      ShowFeatureTippy($el, arguments[1])
    })
  } else if (isElement(selector)) {
    init()
  }
  function init () {
    title = selector.dataset.title || title
    textContent = selector.dataset.content || textContent
    side = selector.dataset.side || side
    classTippy = selector.dataset.class || classTippy
    timeActive = +selector.dataset.time || timeActive
    count = +selector.dataset.count || count
    identifyer = selector.dataset.identifyer || identifyer
    strategy = selector.dataset.strategy || strategy
    interval = +selector.dataset.interval || interval

    const conditionInstance = new ConditionsView({
      identifyer,
      count,
      interval
    })

    tippyInstance = tippy(selector, {
      content: `
        <div class="js-tippy__title-box">
          <div class="js-tippy__icon"></div>
          <h3 class="js-tippy__title">${title}</h3>
        </div>
        <div>${textContent}</div>
      `,
      placement: side,
      theme: classTippy,
      hideOnClick: false
    })

    if (conditionInstance.isNeedToShow()) {
      conditionInstance.increaseCounter()
      getComputedStyle(selector).display === 'none'
        ? tippyInstance.disable()
        : tippyAction()
    } else {
      tippyInstance.disable()
    }
  }

  function tippyAction () {
    if (strategy === 'click') {
      tippyInstance.show()
      document.addEventListener('click', disable)
    } else if (strategy === 'time') {
      tippyInstance.show()
      setTimeout(tippyInstance.disable, timeActive)
    }
  }

  function disable () {
    tippyInstance.disable()
    document.removeEventListener('click', disable)
  }
}
