import isElement from 'lodash.iselement'
import merge from 'lodash.merge'
import shareToGlobal from './ShareToGlobal'
import isTouchDevice from 'is-touch-device'

const list = []

export default function ProductCardSlider (
  selector = 'js-product-card-slider',
  {
    classes = {}
  } = {}
) {
  let cls
  let $photoWrapper
  let $photoArr
  let $dotArr
  let $photoActive
  let $dotActive
  let photoWrappertWidth
  let countPhotos
  let arrCoord = []
  let active = 0
  let startX = 0
  let $dotsList
  let targetCoords

  if (typeof selector === 'string') {
    const arr = Array.from(document.querySelectorAll(selector))
    arr.forEach(($el) => {
      ProductCardSlider($el, arguments[1])
    })
  } else if (isElement(selector) && !selector.dataset.productCardSliderActive) {
    init()
  }

  function init () {
    cls = getClasses(classes)
    $photoWrapper = selector
    $photoWrapper.dataset.productCardSliderActive = true
    $dotsList = $photoWrapper.querySelector(`.${cls.dots.dotWrap}`)
    $photoActive = $photoWrapper.querySelector(`.${cls.photos.active}`)
    $photoArr = Array.from($photoWrapper.querySelectorAll(`.${cls.photos.photo}`))
    if ($photoArr.length === 1) return

    countPhotos = $photoArr.length

    if (isTouchDevice()) {
      $photoWrapper.addEventListener('touchstart', onTouchStart)
      $photoWrapper.addEventListener('touchend', onTouchEnd)
    } else {
      $photoWrapper.addEventListener('mousemove', onMouseMove)
      $photoWrapper.addEventListener('mouseleave', onMouseLeave)
      window.addEventListener('resize', function () {
        arrCoord = []
        photoWrappertWidth = null
        targetCoords = null
      })
    }

    createDots()
    list.push(externalAPI())
  }

  function calculateSlider () {
    photoWrappertWidth = $photoWrapper.offsetWidth
    arrCoord = createArrCoord()
  }

  function createArrCoord () {
    const arr = []
    $photoArr.forEach((el, index) => {
      let start = 0
      let finish
      const div = dividWidth()
      if (index === 0) {
        finish = div
      } else {
        finish = div + arr[index - 1].finish
        start = arr[index - 1].finish
      }
      const obj = { id: index, start, finish }
      arr.push(obj)
    })
    return arr
  }

  function dividWidth () {
    return parseInt(photoWrappertWidth / countPhotos)
  }

  function onMouseMove (e) {
    const target = $photoWrapper
    if (!targetCoords) {
      targetCoords = target.getBoundingClientRect()
    }

    const xCoord = parseInt(e.clientX - targetCoords.left)

    if (!photoWrappertWidth) calculateSlider()

    arrCoord.forEach(el => {
      if (xCoord > el.start && xCoord < el.finish) {
        enable(el.id)
      }
    })
  }

  function createDots () {
    let $dot
    $photoArr.forEach(() => {
      $dot = document.createElement('div')
      $dot.classList.add(cls.dots.dot)
      $dotsList.appendChild($dot)
    })
    $dot = $dotsList.querySelectorAll(`.${cls.dots.dot}`)
    $dot[0].classList.add(cls.dots.active)
    $dotActive = $photoWrapper.querySelector(`.${cls.dots.active}`)
    $dotArr = Array.from($photoWrapper.querySelectorAll(`.${cls.dots.dot}`))
  }

  function onMouseLeave () {
    $photoActive.classList.remove(cls.photos.active)
    $photoActive = $photoArr[0]
    $photoActive.classList.add(cls.photos.active)
    $dotActive.classList.remove(cls.dots.active)
    $dotActive = $dotArr[0]
    $dotActive.classList.add(cls.dots.active)
  }

  function enable (id) {
    active = id
    $photoActive.classList.remove(cls.photos.active)
    $photoActive = $photoArr[active]
    $photoActive.classList.add(cls.photos.active)
    $dotActive.classList.remove(cls.dots.active)
    $dotActive = $dotArr[active]
    $dotActive.classList.add(cls.dots.active)
  }

  function next () {
    if (active === countPhotos - 1) {
      active = 0
    } else {
      active++
    }
    enable(active)
  }

  function prev () {
    if (active === 0) {
      active = countPhotos - 1
    } else {
      active--
    }
    enable(active)
  }

  function onTouchStart (e) {
    startX = e.changedTouches[0].screenX
  }

  function onTouchEnd (e) {
    const diffX = e.changedTouches[0].screenX - startX
    const absDiff = Math.abs(diffX)

    if (absDiff < 15) {
      return
    }

    if (diffX >= 0) {
      prev()
    } else {
      next()
    }
  }

  function getClasses (obj) {
    return merge(
      {
        photos: {
          photos: 'js-product-card-slider__photo',
          active: 'js-product-card-slider__photo-wrapper_active'
        },
        dots: {
          dotWrap: 'js-product-card-slider__photo-slider-dots',
          dot: 'js-product-card-slider__photo-slider-dot',
          active: 'js-product-card-slider__photo-slider-dot_active'
        }
      },
      obj
    )
  }

  function externalAPI () {
    return {
    }
  }
}

function getProductCardSlider (node) {
  return list.find((el) => el.$photoWrapper === node)
}

shareToGlobal.share('ProductCardSlider', ProductCardSlider)
shareToGlobal.share('getProductCardSlider', getProductCardSlider)
